import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import CreateEditShowOrder from '../../components/ShowOrders/CreateEditShowOrder/CreateEditShowOrder';
import { useAppContext } from "../../libs/contextLib";
import LoadingOverlay from 'react-loading-overlay';

const CreateEditShowOrderPage = (props) => {

    const { showOrderId } = useParams();

    const { showOrders, loadShowOrder, isLoadingShowOrders } = useAppContext();
    const [showOrder, setShowOrder] = useState(null);

    useEffect(() => {
        const foundShowOrder = showOrders.find((order) => order.id === showOrderId);
        if (foundShowOrder) {
            setShowOrder(foundShowOrder);
        }
    }, [showOrders]);

    useEffect(() => {
        const loadOrder = async () => {
            if (showOrder && (!showOrder.keyedActors || showOrder.keyedActors.length <= 0)) {
                const loadedOrder = await loadShowOrder(showOrderId);
                setShowOrder(loadedOrder);
            }
        };
        loadOrder();
    }, [showOrder]);

    return <LoadingOverlay
        active={isLoadingShowOrders || !showOrder || !showOrder.keyedActors}
        spinner
        fadeSpeed={0}
    >
        <CreateEditShowOrder
            key={showOrderId}
            readOnly={false}
            showOrder={showOrder}
        />
    </LoadingOverlay>
};

export default CreateEditShowOrderPage;