import React, { useState } from 'react';
import 'element-theme-default';
import ConfirmDeleteDialog from '../../ConfirmDeleteDialog/ConfirmDeleteDialog';
import AttributeTab from './Tabs/AttributeTab/AttributeTab';
import MainTab from './Tabs/MainTab/MainTab';
import SizingTab from './Tabs/SizingTab/SizingTab';
import useModal from '../../../hooks/useModal';
import './CreateEditItem.scss';
import { Button, Tab, Tabs, Form } from "react-bootstrap";

const CreateEditItem = (props) => {
    const MAIN_TAB = "main";
    const ATTRIBUTE_TAB = "attributes";
    const SIZING_TAB = "sizing";
    const DELETE_IMAGE_MESSAGE = 'Are you sure you want to delete this image?';

    const { typeOptions, conditionOptions, colorOptions, periodOptions, tagOptions, item, handleSubmit, onCancel, displayVerb } = props;
    const [description, setDescription] = useState(item ? item.description : '');
    const [itemType, setItemType] = useState(item ? item.itemType : '');
    const [itemCondition, setItemCondition] = useState(item ? item.itemCondition : null);
    const [colors, setColors] = useState(item ? item.colors : []);
    const [periods, setPeriods] = useState(item ? item.periods : []);
    const [tags, setTags] = useState(item ? item.tags : []);    
    const [images, setImages] = useState(item ? item.images : []);
    const [sizing, setSizing] = useState(item ? item.sizing : null);
    const [activeAttributeTab, setActiveAttributeTab] = useState(MAIN_TAB);
    const {isShowing, toggle} = useModal();
    const [imageIndexToBeDeleted, setImageIndexToBeDeleted] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const [validated, setValidated] = useState(false);
    
    const confirmDelete = (index) => {
        setImageIndexToBeDeleted(index);
        toggle();
    }

    const handleImageDelete = () => {
        setImages(images.filter( (image, imageIndex) => { return imageIndex !== imageIndexToBeDeleted; }));
    }

    const onSubmit = async (event) => {
        setIsSaving(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setActiveAttributeTab(MAIN_TAB);
            setIsSaving(false);
        } else {
            const submittedItem = {
                description: description,
                itemType: itemType,
                colors: colors ?? [],
                periods: periods ?? [],
                tags: tags ?? [],
                images: images,
                sizing: sizing
            }
            if (itemCondition) {
                submittedItem['itemCondition'] = itemCondition;
            }
            await handleSubmit(submittedItem);
            setIsSaving(false);
        }
        setValidated(true);
    }

    return (
        <Form noValidate validated={validated} onSubmit={onSubmit}>
        <ConfirmDeleteDialog
                isShowing={isShowing}
                hide={toggle}
                onConfirm={handleImageDelete}
                message={DELETE_IMAGE_MESSAGE}
        />
        <Tabs
            defaultActiveKey={MAIN_TAB}
            activeKey={activeAttributeTab}
            onSelect={(tab) => setActiveAttributeTab(tab)}
        >
            <Tab eventKey={MAIN_TAB} title="Main">
                <MainTab
                    typeOptions={typeOptions}
                    tagOptions={tagOptions}
                    tags={tags}
                    setTags={setTags}
                    description={description}
                    setDescription={setDescription}
                    itemType={itemType}
                    setItemType={setItemType}
                    validated={validated}
                    images={images}
                    setImages={setImages}
                    confirmDelete={confirmDelete}
                />
            </Tab>
            <Tab eventKey={ATTRIBUTE_TAB} title="Attributes">
                <AttributeTab
                    conditionOptions={conditionOptions}
                    colorOptions={colorOptions}
                    periodOptions={periodOptions}
                    itemCondition={itemCondition}
                    setItemCondition={setItemCondition}
                    colors={colors}
                    setColors={setColors}
                    periods={periods}
                    setPeriods={setPeriods}
                />
            </Tab>
            <Tab eventKey={SIZING_TAB} title="Sizing">
                <SizingTab
                    sizing={sizing}
                    setSizing={setSizing}
                 />
            </Tab>
        </Tabs>
        <div className='create-update-button'>
            <Button
                className="btn btn-primary"
                type="submit"
                disabled={isSaving}
                data-testid={displayVerb + '-button'}
            >
                {isSaving ? 'Saving…' : displayVerb }
            </Button>
            <Button
                type="button"
                className="btn btn-danger"
                onClick={onCancel}
                data-testid='cancel-button'
            >
                Cancel 
            </Button>
        </div>
        </Form>
    );

}

export default CreateEditItem;
