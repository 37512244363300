import React from 'react';
import chroma from 'chroma-js';
import { useAccordionButton } from 'react-bootstrap';

export const titleCase = (str) => {
  if(!str) {
    return str;
  }
  str.toLowerCase();
  var strAr = str.split(" ");
  for (var i = 0; i < strAr.length; i++) {
    strAr[i] = strAr[i].charAt(0).toUpperCase() + strAr[i].substring(1).toLowerCase();
  }
  str = strAr.join(" ");
  return str;
}

export const passwordResetRequired = (user) => {
  return (
    user &&
    user.challengeName === 'NEW_PASSWORD_REQUIRED'
  );
};

export const colorStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    let colorValue = data.value.replace(/ /g, '');
    if (!chroma.valid(colorValue)) {
      colorValue = 'black';
    }
    const color = chroma(colorValue);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? colorValue
          : isFocused
            ? chroma.contrast(color, 'white') < 1.15
              ? '#efefef'
              : color.alpha(0.1).css()
            : null,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : chroma.contrast(color, 'white') < 1.5
            ? color.darken(2).css()
            : colorValue,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled && (isSelected ? colorValue : color.alpha(0.3).css()),
      },
    };
  },
  multiValue: (styles, { data }) => {
    let colorValue = data.value.replace(/ /g, '');
    if (!chroma.valid(colorValue)) {
      colorValue = 'black';
    }
    const color = chroma(colorValue);
    return {
      ...styles,
      backgroundColor:
        chroma.contrast(color, 'white') < 1.25
          ? color.alpha(0.1).darken(4).css()
          : color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => {
    let colorValue = data.value.replace(/ /g, '');
    if (chroma.valid(colorValue)) {
      const color = chroma(colorValue);
      if (chroma.contrast(color, 'white') < 1.5) {
        colorValue = color.darken(2).css();
      }
    }
    return {
      ...styles,
      color: colorValue,
    }
  },
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.value,
    ':hover': {
      backgroundColor: data.value,
      color: 'white',
    },
  }),
};

export const convertItemsToSelectBoxValueAndLabel = (items, valueField, labelField) => {
  if (Array.isArray(items)) {
    items = items.map((item) => {
      return { value: item[valueField], label: item[labelField] }
    });
  } else {
    items = { value: items, label: items }
  }
  return items;
}

export const formatCurrency = (amount) => {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return formatter.format(amount);
}

export const formatDate = (date) => {
  if (date) {
    return new Date(date).toDateString();
  }
  return '';
};

export const deepClone = (object) => { 
  try {
    return JSON.parse(JSON.stringify(object));
  } catch (e) {
    console.log("ERROR", e);
    return undefined;
  }
}

export const convertCharactersArrayToRoleString = (characters) => {
  return characters.map((character, index) => 
      character.name
  ).join(', ');
};

export const convertCharactersArrayToCostumes = (characters) => {
  let costumesArray = [];
  characters.forEach((character,index) => {
      costumesArray = costumesArray.concat(character.costumes);
  }
  );
  return costumesArray;
}

export const convertRolesStringtoCharactersArray = (actors) => {
  let characters = [];

  const getRolesFromActor = (actor) => {
    const roles = actor.roles || '';
    const actorCharacters = roles.split(',');
    return actorCharacters;
  };

  if(Array.isArray(actors)) {
    actors.forEach((actor,index) => {
      characters = characters.concat(getRolesFromActor(actor));
    });
  } else if(actors) {
    Object.keys(actors).forEach((key, index) => {
      const actor = actors[key];
      characters = characters.concat(getRolesFromActor(actor));
    });  
  }
  return characters.filter((value, index, self) => self.indexOf(value) === index && value !== '');
}

export const stripParensFromCustomerWhenEmailIsEmpty = (customer) => {
  if(!customer) {
    return null;
  }
  if (customer.charAt(customer.length-1) === ')' &&
    customer.charAt(customer.length-2) === '(') {
      return customer.substring(0, customer.length-3);
    }
  return customer;
}

export const stripParentheses = (string) => {
  if (string) {
    return string.replace(/ *\([^)]*\) */g, "");
  }
  return '';
}

export const ContextAwareToggle = ({ children, eventKey, callback, className }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));

  return (
      <button
          type="button"
          onClick={decoratedOnClick}
          className={className}
      >
          {children}
      </button>
  );
}