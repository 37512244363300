import React, { useState } from 'react';
import './ActorList.scss';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useAppContext } from "../../../libs/contextLib";
import useModal from '../../../hooks/useModal';
import ConfirmDeleteDialog from '../../ConfirmDeleteDialog/ConfirmDeleteDialog';

const ActorList = (props) => {

    const CONFIRM_DELETE_ACTOR_MESSAGE = 'Are you sure you want to delete this actor? This action cannot be undone.';

    const { showOrder, actors } = props;
    const { removeActor, isLoadingShowOrders } = useAppContext();
    const { isShowing: confirmDeleteShowing, toggle: toggleConfirmDelete } = useModal();
    const [actorToBeDeleted, setActorToBeDeleted] = useState(null);
    const navigate = useNavigate();

    if (!actors) {
        return '';
    }

    const openTagsWindow = async (key) => {
        window.open('/orders/' + showOrder.id + '/actors/' + key + '/tags', "_blank");
    }

    const openSheetWindow = async (key) => {
        window.open('/orders/' + showOrder.id + '/actors/' + key + '/sheets', "_blank");
    }

    const confirmRemoveActor = (actorKey) => {
        setActorToBeDeleted(actorKey);
        toggleConfirmDelete();
    }

    return <>
        <ConfirmDeleteDialog
            isShowing={confirmDeleteShowing}
            hide={toggleConfirmDelete}
            onConfirm={() => removeActor(showOrder.id, actorToBeDeleted)}
            message={CONFIRM_DELETE_ACTOR_MESSAGE}

        />
        <div>
            {Object.keys(actors)
                .sort((a, b) => {
                    const firstActor = actors[a];
                    const secondActor = actors[b];
                    if (!firstActor.customer?.value || !secondActor.customer?.value) {
                        return 0;
                    }
                    var textA = firstActor.customer.value.toUpperCase();
                    var textB = secondActor.customer.value.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })
                .map((key, index) => {
                    const actor = actors[key];
                    const actorLink = "/orders/" + showOrder.id + '/' + key;

                    return (
                        <div key={index} className='actorlist-cell'>
                                <div className='actorlist-info'>
                                    <Link key={index} to={actorLink}>
                                        <div className='actorlist-customer'>{actor.customer.value}</div>
                                        <div className='actorlist-roles'>{actor.roles}</div>
                                    </Link>
                                </div>
                                <div className='actorlist-editremove-btn'>
                                    <Button className='btn-info' onClick={() => {navigate(actorLink)}}>
                                        Add Meas. / Costumes
                                    </Button>
                                    <Button
                                        className='btn-danger actorlist-btn'
                                        onClick={() => confirmRemoveActor(key)}
                                        disabled={isLoadingShowOrders}
                                    >
                                        {isLoadingShowOrders ? 'Working...' : 'Delete Actor'}
                                    </Button>
                                </div>
                                                                <div className='actorlist-quickprint-btn'>
                                    <b>Quick print:</b>
                                    <Button className='btn-primary' onClick={() => openTagsWindow(key)}>
                                        Measurement Tags
                                    </Button>
                                    <Button className='btn-warning' onClick={() => openSheetWindow(key)}>
                                        Costume Sheets
                                    </Button>
                                </div>
                        </div>
                    )
                })}
        </div></>;
};

export default ActorList;