import React, { useState, useEffect, useRef } from 'react';
import { Tab, Form, Button } from "react-bootstrap";
import ItemAttributeDropdown from "../../../ItemAttributeDropdown/ItemAttributeDropdown";
import ImageCarouselWithToolbar from '../../../ImageCarouselWithToolbar/ImageCarouselWithToolbar';
import Webcam from 'react-webcam';
import { uploadIcon, imagesIcon, switchIcon, cameraIcon } from '../../../../../libs/icons';

const MainTab = (props) => {

    const {
        typeOptions,
        tagOptions,
        description,
        setDescription,
        itemType,
        setItemType,
        validated,
        images,
        setImages,
        confirmDelete,
        tags,
        setTags
    } = props;

    const IMAGES_TAB = "images";
    const CAMERA_TAB = "camera";
    const USER_CAMERA = "user";
    const ENVIRONMENT_CAMERA = { exact: "environment" };

    const [activeImageTab, setActiveImageTab] = useState(IMAGES_TAB);
    const [activeCarouselImage, setActiveCarouselImage] = useState(0);
    const [cameraFacing, setCameraFacing] = useState(USER_CAMERA);

    const hiddenFileInput = useRef(null);
    const webcam = useRef(null);

    const videoConstraints = {
        facingMode: cameraFacing,
        width: 1280,
        height: 960,
        aspectRatio: 1.33333334
    };
    const imageHeight = '350';
    const imageWidth = '350';

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    }

    const firstLoad = useRef(true);

    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            return;
        }
        setActiveCarouselImage(images.length - 1);
        setActiveImageTab(IMAGES_TAB);
    },
        [images]
    );

    const capture = (event) => {
        event.preventDefault();
        const imageSrc = webcam.current.getScreenshot();
        setImages([...images, imageSrc]);
    }

    const switchCamera = () => {
        setCameraFacing(cameraFacing === USER_CAMERA ? ENVIRONMENT_CAMERA : USER_CAMERA);
    }

    const itemsForCarousel = () => {
        return images ? images.map((image, index) => {
            return {
                id: index,
                description: '',
                image: image
            }
        }) : [];
    }

    const uploadButton = () => {
        return (
            <Button
                className="upload-button"
                onClick={() => { hiddenFileInput.current.value = ''; hiddenFileInput.current.click(); }}
            >
                {uploadIcon}
            </Button>
        );
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleUploadedImage = async (event) => {
        if (event.target.files.length <= 0) {
            return false;
        }
        const file = event.target.files[0];

        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            alert('Picture size cannot exceed 5MB');
            return false;
        }

        const base64encoded = await toBase64(file);
        setImages([...images, base64encoded]);
    }

    return (
        <div>
            <input
                onChange={file => handleUploadedImage(file)}
                type="file"
                ref={hiddenFileInput}
                style={{ display: 'none' }}
            />
            <div>
                Description:
                <Form.Control
                    onChange={handleDescriptionChange}
                    value={description}
                    data-testid='item-name'
                    placeholder="Description"
                    required={true}
                    minLength={1}
                    type="text"
                />
                <Form.Control.Feedback type="invalid">
                    Please choose a name for this item.
                </Form.Control.Feedback>
            </div>
            <div>
                Type:
                <ItemAttributeDropdown
                    isMulti={false}
                    name={"type"}
                    id={"type"}
                    options={typeOptions}
                    required={true}
                    onChange={setItemType}
                    creatable={true}
                    values={itemType}
                    hasValidated={validated}
                />
            </div>
            <div>
                Tags:
                <ItemAttributeDropdown
                    isMulti={true}
                    name={"tags"}
                    id={"tags"}
                    options={tagOptions}
                    onChange={setTags}
                    creatable={true}
                    values={tags}
                />
            </div>
            <Tab.Container
                defaultActiveKey={IMAGES_TAB}
                activeKey={activeImageTab}
            >
                <Tab.Content>
                    <Tab.Pane className={"image-tab"} eventKey={IMAGES_TAB}>
                        <div className='image-tab'>
                            {imagesIcon}
                            &nbsp; Images:
                        </div>
                        <ImageCarouselWithToolbar
                            items={itemsForCarousel()}
                            activeCarouselImage={activeCarouselImage}
                            onDeleteClick={confirmDelete}
                            uploadButton={uploadButton()}
                            onCameraClick={() => setActiveImageTab(CAMERA_TAB)}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey={CAMERA_TAB}>
                        <Webcam
                            className={"webcam-preview"}
                            audio={false}
                            height={imageHeight}
                            ref={webcam}
                            screenshotFormat="image/jpeg"
                            width={imageWidth}
                            videoConstraints={videoConstraints}
                        />
                        <div className='action-buttons'>
                            <Button className='image-list-button' onClick={() => setActiveImageTab(IMAGES_TAB)}>
                                {imagesIcon}
                            </Button>
                            <Button className='switch-camera-button' onClick={switchCamera}>
                                {switchIcon}
                            </Button>
                            {uploadButton()}
                            <Button className='capture-button' onClick={capture}>
                                {cameraIcon('2em')}
                            </Button>
                        </div>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    );
}

export default MainTab;