import React from 'react';
import ReactDOM from 'react-dom';
import { Modal, Button } from 'react-bootstrap';
import './ConfirmDeleteDialog.scss';

const ConfirmDeleteDialog = ({ isShowing, hide, onConfirm, message, buttonLabel }) => isShowing ? ReactDOM.createPortal(
    <Modal
        show={isShowing}
        onHide={hide}
    >
        <Modal.Dialog
            title="Are you sure?"
            size="small"
        >
            <Modal.Body>
                <span data-testid='confirm-delete-message'>{message}</span>
            </Modal.Body>
            <Modal.Footer className="dialog-footer">
                <Button onClick={hide} data-testid='cancel-button'>Cancel</Button>
                <Button
                    type="primary"
                    className='btn-danger confirm-delete-buttons'
                    onClick={() => { hide(); onConfirm(); }}
                    data-testid='yes-button'
                >{buttonLabel ?? 'Delete'}</Button>
            </Modal.Footer>
        </Modal.Dialog>
    </Modal>, document.body
) : null;

export default ConfirmDeleteDialog;