import React, { useState } from 'react';
import "./ManageInventoryPage.scss";
import ItemList from '../ItemList/ItemList';
import ConfirmDeleteDialog from '../../ConfirmDeleteDialog/ConfirmDeleteDialog';
import useModal from '../../../hooks/useModal';
import { useAppContext } from "../../../libs/contextLib";

const ManageInventoryPage = (props) => {

    const DELETE_ITEM_MESSAGE = 'Are you sure you want to delete this item?  This action cannot be undone.';
    const { selectItem, initialFilters } = props;
    const { items, attributes, deleteItem } = useAppContext();

    const { isShowing, toggle } = useModal();
    const [idToBeDeleted, setIdToBeDeleted] = useState(null);

    const confirmDelete = (id) => {
        setIdToBeDeleted(id);
        toggle();
    }

    const handleItemDelete = async () => {
        await deleteItem(idToBeDeleted);
        setIdToBeDeleted(null);
    }

    return (
        <>
            <ConfirmDeleteDialog
                isShowing={isShowing}
                hide={toggle}
                onConfirm={handleItemDelete}
                message={DELETE_ITEM_MESSAGE}
            />
                <ItemList
                    types={attributes?.itemType}
                    colors={attributes?.colors}
                    periods={attributes?.periods}
                    tags={attributes?.tags}
                    conditions={attributes?.itemCondition}
                    items={items}
                    handleItemDelete={confirmDelete}
                    selectItem={selectItem}
                    initialFilters={initialFilters}
                />
        </>
    );
}

export default ManageInventoryPage;