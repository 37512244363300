import React from 'react';
import Image from "react-bootstrap/Image";
import './ImageDisplay.scss';

const ImageDisplay = (props) => {

    return <div className={"item-style"}>
        <div className="image-display">
            <div className="item-description">
                {props.description}
            </div>
            <div className='item-image'>
            <Image
                src={props.filename}
                className="image"
            />
            </div>
        </div>
    </div>;
}

export default ImageDisplay;
