import React, { useState, useEffect } from 'react';
import './App.scss';
import { Link, Route, Routes, BrowserRouter } from "react-router-dom";
import 'element-theme-default';
import { StateProvider } from '../../store';
import { AppContext } from "../../libs/contextLib";
import { passwordResetRequired } from '../../utilities';
import { Button } from "react-bootstrap";
import {
    peopleIcon,
    inventoryIcon,
    boxesIcon
} from '../../libs/icons';
import Login from '../User/Login/Login';
import ForgotPassword from '../User/ForgotPassword/ForgotPassword';
import CompleteRegistration from '../User/CompleteRegistration/CompleteRegistration';
import ManageInventoryPage from '../Inventory/ManageInventoryPage/ManageInventoryPage';
import BulkInventoryPage from '../Inventory/BulkInventoryPage/BulkInventoryPage';
import ManageShowOrdersPage from '../ShowOrders/ManageShowOrdersPage/ManageShowOrdersPage';
import TagsPdf from '../Pdf/TagsPdf/TagsPdf';
import CostumeSheetViewer from '../Pdf/CostumeSheetViewer/CostumeSheetViewer';
import { useCustomerApi } from '../../hooks/useCustomerApi';
import { useItemApi } from '../../hooks/useItemApi';
import { useItemAttributeApi } from '../../hooks/useItemAttributeApi';
import { useShowApi } from '../../hooks/useShowApi';
import { useShowOrderApi } from '../../hooks/useShowOrderApi';
import CreateEditShowOrderPage from '../../Pages/CreateEditShowOrderPage/CreateEditShowOrderPage';
import CreateEditActorPage from '../../Pages/CreateEditActorPage/CreateEditActorPage';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { useIdleTimer } from 'react-idle-timer';

const App = () => {

    const APP_TIMEOUT = 1000 * 60 * 15;

    const [isAuthenticated, userHasAuthenticated] = useState(false);
    const [forgotPasswordClicked, setForgotPasswordClicked] = useState(false);
    const [user, setUser] = useState(false);
    // const [user, setUser] = useState({ signInUserSession: { idToken: { jwtToken: 'blah' }}});
    const [customers, isLoadingCustomers, { fetchCustomers }] = useCustomerApi();
    const [attributes, isLoadingAttributes] = useItemAttributeApi();
    const [acaHeaders, setAcaHeaders] = useState({});
    const [shows, isLoadingShows, { fetchShows }] = useShowApi(acaHeaders);
    const [showOrders, isLoadingShowOrders, { loadShowOrder, saveActor, saveShowOrderAttribute, removeActor, createShowOrder, removeShowOrder }] = useShowOrderApi(acaHeaders);
    const [items, isLoadingItems, typeFilters, colorFilters, periodFilters, tagFilters, { setItems, setTypeFilters, setColorFilters, setPeriodFilters, setTagFilters, updateItem, createItem, patchItem, deleteItem }] = useItemApi(acaHeaders);

    const waveHeaders = {
        'Authorization': 'Bearer ' + process.env.REACT_APP_WAVE_TOKEN,
        'Content-Type': 'application/json'
    };

    useEffect(() => {
        let idToken = null;
        if (user.signInUserSession) {
            idToken = user.signInUserSession.idToken.jwtToken;
        }
        setAcaHeaders({
                'x-api-key': process.env.REACT_APP_API_KEY,
                'Authorization': "Bearer " + idToken
            });
    }, [user]);

    const onIdle = () => {
        handleLogout();
    }
    const onActive = () => {};
    const idleTimer = useIdleTimer({ onIdle, onActive, timeout: APP_TIMEOUT });

    const handleLogout = () => {
        userHasAuthenticated(false);
    };

    const renderMainMenu = () => {
        return (
            <div className='menu-div'>
                {/* <Link to="/orders">
                    <Button
                        block
                        bsSize="large"
                        type="button"
                        className="menu-button"
                    >
                        {personIcon('2em')} <br />Appointment Orders
                    </Button>
                </Link> */}
                <Link to="/orders">
                    <Button
                        block
                        bsSize="large"
                        type="button"
                        className="menu-button"
                    >
                        {peopleIcon('2em')} <br /> Orders
                    </Button>
                </Link>
                <Link to="/inventory">
                    <Button
                        block
                        bsSize="large"
                        type="button"
                        className="menu-button"
                    >
                        {inventoryIcon('2em')} <br /> Inventory Management
                    </Button>
                </Link>
                <Link to="/bulk_inventory_edit">
                    <Button
                        block
                        bsSize="large"
                        type="button"
                        className="menu-button"
                    >
                        {boxesIcon('2em')} <br /> Bulk Inventory
                    </Button>
                </Link>
            </div>
        );
    };

    const renderTopNavBar = () => {
        return (
            <><div>

                <Link to="/">
                    <Button
                        className='top-nav-bar btn-info'
                    >
                        Main Menu
                    </Button>
                </Link>
                {/* <Link to="/orders">
                    <Button
                        className='top-nav-bar btn-info'
                    >
                        Appt. Orders
                    </Button>
                </Link> */}
                <Link to="/orders">
                    <Button
                        className='top-nav-bar btn-info'
                    >
                        Orders
                    </Button>
                </Link>
                <Link to="/inventory">
                    <Button
                        className='top-nav-bar btn-info'
                    >
                        Inventory
                    </Button>
                </Link>
                <Link to="/bulk_inventory_edit">
                    <Button
                        className='top-nav-bar btn-info'
                    >
                        Bulk Inventory
                    </Button>
                </Link>
                <Button
                    className='top-nav-bar btn-danger'
                    onClick={handleLogout}
                >
                    Logout
                </Button>
            </div>
            <Breadcrumbs />
            </>
        )
    };

    return (
        <AppContext.Provider value={{
            isAuthenticated,
            userHasAuthenticated,
            user,
            setUser,
            acaHeaders,
            waveHeaders,
            forgotPasswordClicked,
            setForgotPasswordClicked,
            customers,
            fetchCustomers,
            items,
            isLoadingItems,
            setItems,
            typeFilters, colorFilters, periodFilters, tagFilters,
            setTypeFilters, setColorFilters, setPeriodFilters, setTagFilters, updateItem, createItem, patchItem, deleteItem,
            attributes, isLoadingAttributes,
            shows, isLoadingShows, fetchShows,
            showOrders, isLoadingShowOrders, loadShowOrder, saveActor, saveShowOrderAttribute, removeActor, createShowOrder, removeShowOrder
        }}
        >
            <StateProvider>
                {isAuthenticated
                    ?
                    <BrowserRouter>
                        <Routes>
                            <Route exact path="/" element={renderMainMenu()} />
                            <Route exact path="/orders" element={<div>{renderTopNavBar()} <ManageShowOrdersPage /></div>} />
                            <Route
                                exact
                                path="/orders/:showOrderId"
                                element={<div>{renderTopNavBar()} <CreateEditShowOrderPage /></div>}
                            />
                            <Route
                                exact
                                path="/orders/:showOrderId/:actorKey"
                                element={<div>{renderTopNavBar()} <CreateEditActorPage /></div>}
                            />
                            <Route 
                                exact
                                path="/inventory"
                                element={<div>{renderTopNavBar()} <ManageInventoryPage /></div>}
                            />
                            <Route 
                                exact
                                path="/bulk_inventory_edit"
                                element={<div>{renderTopNavBar()} <BulkInventoryPage /></div>}
                            />                            
                        </Routes>
                    </BrowserRouter>
                    : <div>
                        <BrowserRouter>
                            <Routes>
                                <Route path="/orders/:showOrderId/actors/:actorKey/tags" element={<TagsPdf />} />
                                <Route path="/orders/:showOrderId/actors/:actorKey/sheets" element={<CostumeSheetViewer />} />
                                <Route path="/orders/:showOrderId/sheets" element={<CostumeSheetViewer />} />
                                <Route path="/orders/:showOrderId/tags" element={<TagsPdf />} />
                                <Route
                                    path="*"
                                    element={
                                        passwordResetRequired(user)
                                            ? <CompleteRegistration />
                                            : forgotPasswordClicked
                                                ? <ForgotPassword />
                                                : <Login />
                                    }
                                />
                            </Routes>
                        </BrowserRouter>
                    </div>
                }
            </StateProvider>
        </AppContext.Provider>
    );
}

export default App;
