import React, {createContext, useReducer} from 'react';

const initialState = {
    types: [],
    colors: [],
    periods: [],
    tags: [],
};

const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ( { children } ) => {

    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case "UPDATE_ATTRIBUTE_OPTIONS":
                return {
                    ...state,
                    types: action.payload
                };
            default:
                return state;
        } }
        , initialState);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider }
