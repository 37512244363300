import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Modal, Form, Button } from "react-bootstrap";
import './CreateCustomerDialog.scss';
import { createCustomer } from '../../../libs/wave/createCustomer';

const CreateCustomerDialog = ({ isShowing, hide, onConfirm, customerName, setCustomerName, setCustomer }) => {

    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const nameInput = useRef(null);

    useEffect(()=>{
        if (nameInput.current) {
            nameInput.current.focus();
        }
    }, [isShowing])

    const handleCustomerNameChange = (event) => {
        setCustomerName(event.target.value);
    }

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
    }

    const handleConfirm = async (event) => {
        try {
            const customer = await createCustomer(customerName, email, phone);
            setCustomer(customer);
            hide();
            onConfirm();
        } catch (error) {
            console.log(error);
        }
    }

    return isShowing ? ReactDOM.createPortal(
        <Modal
        show={isShowing}
        onHide={hide}
    >
            <Modal.Dialog
                title="Create Customer"
                size="full"
            >
                <Modal.Body>
                    <Form.Control
                        onChange={handleCustomerNameChange}
                        value={customerName}
                        placeholder="Name"
                        required={true}
                        type="text"
                        ref={nameInput}
                        data-testid="customer-name"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter a name for this customer.
                    </Form.Control.Feedback>
                    <Form.Control
                        onChange={handleEmailChange}
                        value={email}
                        placeholder="Email"
                        required={true}
                        type="text"
                        data-testid="customer-email"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter an email for this customer.
                    </Form.Control.Feedback>
                    <Form.Control
                        onChange={handlePhoneChange}
                        value={phone}
                        placeholder="Phone"
                        required={true}
                        type="text"
                        data-testid="customer-phone"
                    />
                </Modal.Body>
                <Modal.Footer className="dialog-footer">
                    <Button data-testid="cancel-button" onClick={hide}>Cancel</Button>
                    <Button data-testid="confirm-button" type="primary" onClick={handleConfirm}>Confirm</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </Modal>, document.body
    ) : null;
}

export default CreateCustomerDialog;