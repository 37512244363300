import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { useAppContext } from "../../../libs/contextLib";
import ItemAttributeDropdown from '../ItemAttributeDropdown/ItemAttributeDropdown';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { caretDown, caretUp, caretDownFill, caretUpFill } from '../../../libs/icons';

const BulkInventoryPage = (props) => {

    const { items, patchItem, attributes, isLoadingItems } = useAppContext();

    const arrayFormatter = (cell, row) => {
        if(Array.isArray(cell)) {
            return cell.join(', ');
        } else {
            return cell;
        }
    };

    const dateFormatter = (cell, row) => {
        return new Date(cell).toLocaleString();
    }

    const sortCaret = (order, column) => {
        if (!order) return (<span>{caretUp}{caretDown}</span>);
        else if (order === 'desc') return (<span>{caretUp}{caretDownFill}</span>);
        else if (order === 'asc') return (<span>{caretUpFill}{caretDown}</span>);
        return null;
      };

    const columns = [{
        dataField: 'description',
        text: 'Description',
        sort: true,
        sortCaret: sortCaret
    },
    {
        dataField: 'itemType',
        text: 'Type',
        sort: true,
        sortCaret: sortCaret,
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
            <ItemAttributeDropdown
                isMulti={false}
                name={"itemType"}
                options={attributes?.itemType}
                onChange={(updatedType) => { patchItem(row.id, 'itemType', updatedType)}}
                creatable={true}
                values={value}
            />
        )
    },
    {
        dataField: 'tags',
        text: 'Tags',
        sort: true,
        sortCaret: sortCaret,
        formatter: arrayFormatter,
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
            <ItemAttributeDropdown
                isMulti={true}
                name={"tags"}
                options={attributes?.tags}
                onChange={(updatedTags) => { patchItem(row.id, 'tags', updatedTags)}}
                creatable={true}
                values={value}
            />
        )
    },
    {
        dataField: 'colors',
        text: 'Colors',
        sort: true,
        sortCaret: sortCaret,
        formatter: arrayFormatter,
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
            <ItemAttributeDropdown
                isMulti={true}
                name={"tags"}
                options={attributes?.colors}
                onChange={(updatedColors) => { patchItem(row.id, 'colors', updatedColors)}}
                creatable={true}
                values={value}
            />
        )
    },
    {
        dataField: 'periods',
        text: 'Periods',
        sort: true,
        sortCaret: sortCaret,
        formatter: arrayFormatter,
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
            <ItemAttributeDropdown
                isMulti={true}
                name={"periods"}
                options={attributes?.periods}
                onChange={(updatedPeriods) => { patchItem(row.id, 'periods', updatedPeriods)}}
                creatable={true}
                values={value}
            />
        )
    },{
        dataField: 'itemCondition',
        text: 'Condition',
        sort: true,
        sortCaret: sortCaret,
        editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => (
            <ItemAttributeDropdown
                isMulti={false}
                name={"itemCondition"}
                options={attributes?.itemCondition}
                onChange={(updatedCondition) => { patchItem(row.id, 'itemCondition', updatedCondition)}}
                creatable={true}
                values={value}
            />
        )
    },{
        dataField: 'submittedAt',
        text: 'Created on',
        sort: true,
        sortCaret: sortCaret,
        formatter: dateFormatter
    },{
        dataField: 'updatedAt',
        text: 'Modified on',
        sort: true,
        sortCaret: sortCaret,
        formatter: dateFormatter
    }];

    const onTableChange = (type, newState) => {
        patchItem(newState.cellEdit.rowId, newState.cellEdit.dataField, newState.cellEdit.newValue);
    };

    return <BootstrapTable
        remote={{ cellEdit: true }}
        striped={true}
        keyField="id"
        data={items}
        columns={columns}
        cellEdit={cellEditFactory({ mode: 'click' })}
        onTableChange={onTableChange}
        loading={isLoadingItems}
        noDataIndication={() => { return <></>}}
        overlay={ overlayFactory({ spinner: true, styles: { overlay: (base) => ({...base, background: 'rgba(77, 77, 77, 0.5)'}) } }) } 
    />;
}

export default BulkInventoryPage;