import React, { useState } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import "./CompleteRegistration.scss";
import { Amplify, Auth } from "aws-amplify";
import { useAppContext } from "../../../libs/contextLib";

const CompleteRegistration = () => {
    const { userHasAuthenticated, user } = useAppContext();
    const [givenName, setGivenName] = useState("");
    const [familyName, setFamilyName] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    
    const validateForm = () => {
        return (
            newPassword === confirmPassword &&
            givenName.length > 0 &&
            familyName.length > 0
        );
    }

    const handleSubmit = async(event) => {
        event.preventDefault();
      
        try {
          await Auth.completeNewPassword(
              user,
              newPassword,
              { family_name: familyName,
                given_name: givenName
              });
          userHasAuthenticated(true);
        } catch (e) {
          alert(e.message);
        }
    }

    Amplify.configure({
        Auth: {
            region: process.env.REACT_APP_REGION,
            userPoolId: process.env.REACT_APP_USER_POOL_ID,
            userPoolWebClientId: process.env.REACT_APP_POOL_WEB_CLIENT_ID,
        }
    });

    
    return (
        <div className="CompleteRegistration">
        <h2>Complete Registration</h2>
        <form onSubmit={handleSubmit}>
            <FormGroup controlId="givenName" bsSize="large">
            <FormLabel>First Name</FormLabel>
            <FormControl
                autoFocus
                type="text"
                value={givenName}
                onChange={e => setGivenName(e.target.value)}
            />
            </FormGroup>
            <FormGroup controlId="familyName" bsSize="large">
            <FormLabel>Last Name</FormLabel>
            <FormControl
                autoFocus
                type="text"
                value={familyName}
                onChange={e => setFamilyName(e.target.value)}
            />
            </FormGroup>
            <FormGroup controlId="newPassword" bsSize="large">
            <FormLabel>New Password</FormLabel>
            <FormControl
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                type="password"
            />
            </FormGroup>
            <FormGroup controlId="confirmPassword" bsSize="large">
            <FormLabel>Confirm Password</FormLabel>
            <FormControl
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                type="password"
            />
            </FormGroup>
            <Button block bsSize="large" disabled={!validateForm()} type="submit">
            Login
            </Button>
        </form>
        </div>
    );
}

export default CompleteRegistration;