import React, { useState, useCallback } from 'react';
import './ShowOrderList.scss';
import { formatCurrency, formatDate } from '../../../utilities';
import { Link, useNavigate } from 'react-router-dom';
import { Accordion, Card, Button } from 'react-bootstrap';
import { useAppContext } from "../../../libs/contextLib";
import useModal from '../../../hooks/useModal';
import ConfirmDeleteDialog from '../../ConfirmDeleteDialog/ConfirmDeleteDialog';
import { ContextAwareToggle } from '../../../utilities';

const ShowOrderList = (props) => {

    const CONFIRM_DELETE_SHOW_ORDER_MESSAGE = 'Are you sure you want to delete this order? This action cannot be undone.';

    const { data } = props;
    const { isLoadingShowOrders, removeShowOrder } = useAppContext();
    const { isShowing: confirmDeleteShowing, toggle: toggleConfirmDelete } = useModal();
    const [showOrderToBeDeleted, setShowOrderToBeDeleted] = useState(null);
    const [activeOrderSortKey, setActiveOrderSortKey] = useState('current');

    const navigate = useNavigate();

    const openTagsWindow = async (showOrderId) => {
        window.open('/orders/' + showOrderId + '/tags', "_blank");
    }

    const openSheetWindow = async (showOrderId) => {
        window.open('/orders/' + showOrderId + '/sheets', "_blank");
    }

    const confirmRemoveShowOrder = (showOrderId) => {
        setShowOrderToBeDeleted(showOrderId);
        toggleConfirmDelete();
    }

    const isTodayOrLater = (deliveryDate) => {
        if(deliveryDate == null) {
            return true;
        }
        const today = new Date();
        today.setHours(0,0,0,0);
        const deliveryDateObject = new Date(deliveryDate);
        return deliveryDateObject.getTime() >= today.getTime();
    }

    const currentOrders = () => {
        const orders = data.filter(order => isTodayOrLater(order.deliveryDate));
        return orders.sort((a,b) => new Date(a.deliveryDate).getTime() < new Date(b.deliveryDate).getTime() ? 1 : -1)
    }

    const pastOrders = () => {
        const orders = data.filter(order => !isTodayOrLater(order.deliveryDate));
        return orders.sort((a,b) => new Date(a.deliveryDate).getTime() < new Date(b.deliveryDate).getTime() ? 1 : -1);
    }

    const orderDisplay = (orders) =>
        orders.map((showOrder, index) => {
            const showOrderLink = "/orders/" + showOrder.id;
            return (<div key={index} className='showorderlist-cell'>
                <div className='showorderlist-info'>
                    <Link key={index} to={showOrderLink}>
                        <div className='showorderlist-show-name'>
                            {showOrder.showName}
                        </div>
                        <div className='showorderlist-customer'>
                            {showOrder.customer.value}
                        </div>
                        <div className='showorderlist-quote'>
                            {formatCurrency(showOrder.quote)}
                        </div>
                        <div className='showorderlist-deliverydate'>
                            {formatDate(showOrder.deliveryDate)}
                        </div>
                    </Link>
                </div>
                <div className='showorderlist-editremove-btn'>
                    <Button className='btn-info' onClick={() => { navigate(showOrderLink) }}>
                        Edit Order / Add Actors
                    </Button>
                    <Button
                        className='btn-danger'
                        onClick={() => confirmRemoveShowOrder(showOrder.id)}
                        disabled={isLoadingShowOrders}
                    >
                        {isLoadingShowOrders ? 'Working...' : 'Delete Order'}
                    </Button>
                </div>
                <div className='showorderlist-quickprint-btn'>
                    <b>Quick print:</b>
                    <Button className='btn-primary' onClick={() => openTagsWindow(showOrder.id)}>
                        Print Measurement Tags
                    </Button>
                    <Button className='btn-warning' onClick={() => openSheetWindow(showOrder.id)}>
                        Print Sheets
                    </Button>
                </div>
            </div>
            )
        })

    const openAccordion = useCallback((eventKey) => {
        if (activeOrderSortKey === eventKey) {
            setActiveOrderSortKey(null);
        } else {
            setActiveOrderSortKey(eventKey);
        }
    }, [activeOrderSortKey]);

    return (<>
        <ConfirmDeleteDialog
            isShowing={confirmDeleteShowing}
            hide={toggleConfirmDelete}
            onConfirm={() => removeShowOrder(showOrderToBeDeleted)}
            message={CONFIRM_DELETE_SHOW_ORDER_MESSAGE}

        />
        <Accordion activeKey={activeOrderSortKey}>
            {currentOrders().length > 0
                ? <Card key={'current'}>
                    <ContextAwareToggle eventKey={'current'} callback={openAccordion} className='order-current-header'>
                        <Card.Header>
                            <div>
                                Current
                            </div>
                        </Card.Header>
                    </ContextAwareToggle>
                    <Accordion.Collapse className={'order-sort-collapse-current'} eventKey={'current'}>
                        <Card.Body className='order-sort-body'>
                            { orderDisplay(currentOrders())}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card> : ''}
                {pastOrders().length > 0
                ? <Card key={'past'}>
                    <ContextAwareToggle eventKey={'past'} callback={openAccordion} className='order-past-header'>
                        <Card.Header>
                            <div>
                                Past
                            </div>
                        </Card.Header>
                    </ContextAwareToggle>
                    <Accordion.Collapse className={'order-sort-collapse-past'} eventKey={'past'}>
                        <Card.Body className='order-sort-body'>
                            { orderDisplay(pastOrders())}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card> : ''}
        </Accordion>
    </>
    );
};

export default ShowOrderList;