import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import './TagsPdf.scss';
import { convertCharactersArrayToRoleString, titleCase, stripParensFromCustomerWhenEmailIsEmpty } from '../../../utilities';

// Create Document Component
const TagsPdf = () => {

    const { showOrderId, actorKey } = useParams();
    const [showOrder, setShowOrder] = useState(null);

    useEffect(() => {
        const getShowOrderInfo = async () => {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/show_orders/' + showOrderId);
            setShowOrder(response.data);
        }
        getShowOrderInfo();
    }, [showOrderId]);

    useEffect(() => {
        if (showOrder !== null) {
            window.print();
        };
    }, [showOrder]);

    const renderRoles = (key) => {
        let roles = '';
        const actorKeyExists = showOrder && key in showOrder.keyedActors;
        const actorContainsCharactersArray = (key) => showOrder.keyedActors[key].characters?.length > 0;

        if (actorKeyExists) {
            if (actorContainsCharactersArray(key)) {
                roles = convertCharactersArrayToRoleString(showOrder.keyedActors[key].characters);
            } else {
                roles = showOrder.keyedActors[key].roles;
            }
        }
        return (
            <div classname='tag-character-name'>
                {roles}
            </div>
        );
    };

    const renderMeasurements = (key) => {
        const actorKeyExists = showOrder && key in showOrder.keyedActors;
        if (actorKeyExists && showOrder.keyedActors[key].measurements?.length > 0) {
            return showOrder.keyedActors[key].measurements.map((measurement, index) => {
                return <div className='tag-measurement'><span className='tag-measurement-label'>{titleCase(measurement.label)}:</span> {measurement.value}</div>;
            });
        }
    }

    const customerName = (key) => {
        if (showOrder) {
            return stripParensFromCustomerWhenEmailIsEmpty(showOrder.keyedActors[key].customer.value);
        }
        return '';
    }

    const renderBox = (key) => {
        return (
            <div className='tag-box'>
                <div className='tag-show-name'>{showOrder?.showName}</div>
                <div className='tag-customer-name'>{customerName(key)}</div>
                <div className='tag-character-section'>{renderRoles(key)}</div>
                <hr className='tag-divider' />
                <div className='tag-bottom-row'>
                    <div className='tag-measurement-section'>{renderMeasurements(key)}</div>
                    <div className='tag-notes-section'>Notes:</div>
                </div>
            </div>
        );
    };

    const renderSheet = (key) => {
        return (
            <div>
                {renderBox(key)}
                {renderBox(key)}
                {renderBox(key)}
                {renderBox(key)}
                {renderBox(key)}
                {renderBox(key)}
                {renderBox(key)}
                {renderBox(key)}
                {renderBox(key)}
            </div>);
    };

    if (!showOrder) {
        return '';
    }
    
    return (
        <>
            {Object.keys(showOrder.keyedActors).map((key) => {
                if (actorKey === undefined ||
                    actorKey === key) {
                    const actor = showOrder.keyedActors[key];
                    return renderSheet(key);
                }
                return '';
            })}
        </>
    )
};

export default TagsPdf;

