import {useEffect, useState} from "react";
import axios from 'axios';

export const useShowApi = (acaHeaders) => {
    const [shows, setShows] = useState([]);
    const [isLoadingShows, setIsLoadingShows] = useState(true);

    const fetchShows = async () => {
        setIsLoadingShows(true);
        if(Object.keys(acaHeaders).length <= 0 || acaHeaders.Authorization === 'Bearer null') {
            setIsLoadingShows(false);
            return [];
        }
        const response = await axios.get(process.env.REACT_APP_API_URL + '/shows', { headers: acaHeaders });
        setShows([...response.data]);
        setIsLoadingShows(false);
    }; 
    useEffect(() => {
        fetchShows();
    }, [acaHeaders]);

    return [shows, isLoadingShows, {setShows, fetchShows}];
}

