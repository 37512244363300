import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CostumeSheet from '../CostumeSheet/CostumeSheet';
import axios from 'axios';

const CostumeSheetViewer = (props) => {

    const { showOrderId, actorKey } = useParams();
    const [showOrder, setShowOrder] = useState(null);

    useEffect(() => {
        const getActorInfo = async () => {
            const response = await axios.get(process.env.REACT_APP_API_URL + '/show_orders/' + showOrderId);
            setShowOrder(response.data);
        }
        getActorInfo();
    },[]);

    useEffect(() => {
        if (showOrder !== null) {
            window.print();
        };
    }, [showOrder]);

    if (!showOrder) {
        return '';
    }
    
    return (
        <>
            {Object.keys(showOrder.keyedActors).map((key) => {
                if (actorKey === undefined ||
                    actorKey === key) {
                    const actor = showOrder.keyedActors[key];
                    return (
                        <CostumeSheet
                            showOrder={showOrder}
                            actor={actor}
                        />
                    );
                }
                return '';
            })}
        </>
    )
};

export default CostumeSheetViewer;