import React, { useState, useEffect } from 'react';
import 'element-theme-default';
import { Button } from 'react-bootstrap';
import ItemAttributeDropdown from '../ItemAttributeDropdown/ItemAttributeDropdown';
import './ItemList.scss';
import ImageCarouselWithToolbar from '../ImageCarouselWithToolbar/ImageCarouselWithToolbar';
import { colorStyles } from '../../../utilities';
import { useAppContext } from "../../../libs/contextLib";
import CreateEditItemDialog from '../CreateEditItemDialog/CreateEditItemDialog';

const ItemList = (props) => {
    const { items, setTypeFilters, setColorFilters, setPeriodFilters, setTagFilters } = useAppContext();

    const { types, colors, periods, tags, conditions, selectItem, initialFilters } = props;

    const [createDialogVisible, setCreateDialogVisible] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [updateOrCreate, setUpdateOrCreate] = useState('Create');
    const [activeCarouselImage, setActiveCarouselImage] = useState(0);
    const [newlyCreatedItemId, setNewlyCreatedItemId] = useState(null);
    const [localTypeFilters, setLocalTypeFilters] = useState(initialFilters ? initialFilters['types'] : []);
    const [localColorFilters, setLocalColorFilters] = useState(initialFilters ? initialFilters['colors'] : []);
    const [localPeriodFilters, setLocalPeriodFilters] = useState(initialFilters ? initialFilters['periods'] : []);
    const [localTagFilters, setLocalTagFilters] = useState(initialFilters ? initialFilters['tags'] : []);
    const [applyButtonActive, setApplyButtonActive] = useState(false);


    const renderedItems = items.map((item, index) => {
        let images = item.images ?? [];
        return {
            image: images.length > 0 ? images[0] : '',
            id: item.id,
            description: item.description
        }
    });

    useEffect(() => {
        setApplyButtonActive(true);
    }, [localTypeFilters, localColorFilters, localPeriodFilters, localTagFilters]);

    useEffect(() => {
        if (!newlyCreatedItemId) {
            return;
        }
        const newlyCreatedItem = renderedItems.filter(item => item.id === newlyCreatedItemId)[0];
        if (selectItem && newlyCreatedItem) {
            selectItem(newlyCreatedItem);
            setNewlyCreatedItemId(null);
        } else {
            const newlyCreatedItemIndex = renderedItems.findIndex(item => item.id === newlyCreatedItemId);
            setActiveCarouselImage(newlyCreatedItemIndex !== -1 ? newlyCreatedItemIndex : 0);
        }
    }, [renderedItems, newlyCreatedItemId, selectItem]);

    const renderFilters = () => {
        return (
            <div className='filters'>
                <div className='filter'>
                    <label>Types:</label>
                    <ItemAttributeDropdown
                        isMulti={true}
                        name={"type"}
                        options={types}
                        onChange={setLocalTypeFilters}
                        creatable={false}
                        values={localTypeFilters}
                    />
                </div>
                <div className='filter'>
                    <label>Colors:</label>
                    <ItemAttributeDropdown
                        isMulti={true}
                        name={"color"}
                        options={colors}
                        onChange={setLocalColorFilters}
                        creatable={false}
                        styles={colorStyles}
                        values={localColorFilters}
                    />
                </div>
                <div className='filter'>
                    <label>Periods:</label>
                    <ItemAttributeDropdown
                        isMulti={true}
                        name={"period"}
                        options={periods}
                        onChange={setLocalPeriodFilters}
                        creatable={false}
                        values={localPeriodFilters}
                    />
                </div>
                <div className='filter'>
                    <label>Tags:</label>
                    <ItemAttributeDropdown
                        isMulti={true}
                        name={"tags"}
                        options={tags}
                        onChange={setLocalTagFilters}
                        creatable={false}
                        values={localTagFilters}
                    />
                </div>
            </div>
        );
    }

    const resetFilters = () => {
        setTypeFilters([]);
        setColorFilters([]);
        setPeriodFilters([]);
        setTagFilters([]);
    };

    const createItem = () => {
        setItemToEdit(null);
        resetFilters();
        setUpdateOrCreate('Create');
        setCreateDialogVisible(true);
    }

    const editItem = (itemIndex) => {
        setItemToEdit(items[itemIndex]);
        setUpdateOrCreate('Update');
        setCreateDialogVisible(true);
    }

    const renderItems = () => {
        return (
            <ImageCarouselWithToolbar
                activeCarouselImage={activeCarouselImage}
                items={renderedItems}
                onDeleteClick={props.handleItemDelete}
                onEditClick={editItem}
                onCreateClick={createItem}
                onSelectClick={selectItem}
            />
        )
    }

    const applyFilters = () => {
        setTypeFilters(localTypeFilters);
        setColorFilters(localColorFilters);
        setPeriodFilters(localPeriodFilters);
        setTagFilters(localTagFilters);
        setApplyButtonActive(false);
    };

    useEffect(() => {
        if(initialFilters) {
            applyFilters();
        }
    }, [initialFilters]);

    return <div>
        <h5>Items:
        </h5>
        <div>{renderItems()}</div>
        <div className='apply-filter-div'>
            <Button
                disabled={!applyButtonActive}
                onClick={applyFilters}>Apply Filters
            </Button>
        </div>
        <div>{renderFilters()}</div>
        <CreateEditItemDialog
            updateOrCreate={updateOrCreate}
            isShowing={createDialogVisible}
            setCreateDialogVisible={setCreateDialogVisible}
            setNewlyCreatedItemId={setNewlyCreatedItemId}
            setItemToEdit={setItemToEdit}
            types={types}
            colors={colors}
            periods={periods}
            tags={tags}
            conditions={conditions}
            itemToEdit={itemToEdit}
        />
    </div>;

}

export default ItemList;
