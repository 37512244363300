import React from 'react';
import { colorStyles } from '../../../../../utilities';
import ItemAttributeDropdown from "../../../ItemAttributeDropdown/ItemAttributeDropdown";


const AttributeTab = (props) => {

    const { 
        conditionOptions,
        colorOptions,
        periodOptions,
        itemCondition,
        setItemCondition,
        colors,
        setColors,
        periods,
        setPeriods
    } = props;

    return (
        <div>
            <div>
                Condition:
                <ItemAttributeDropdown
                    isMulti={false}
                    name={"itemCondition"}
                    id={"itemCondition"}
                    options={conditionOptions}
                    onChange={setItemCondition}
                    creatable={true}
                    values={itemCondition}
                />
            </div>
            <div>
                Colors:
                <ItemAttributeDropdown
                    isMulti={true}
                    name={"color"}
                    id={"color"}
                    options={colorOptions}
                    onChange={setColors}
                    creatable={true}
                    values={colors}
                    styles={colorStyles}
                />
            </div>
            <div>
                Periods:
                <ItemAttributeDropdown
                    isMulti={true}
                    name={"period"}
                    id={"period"}
                    options={periodOptions}
                    onChange={setPeriods}
                    creatable={true}
                    values={periods}
                />
            </div>
        </div>
    );
}

export default AttributeTab;