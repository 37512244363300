import React, {useEffect, useState, useRef } from 'react';
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { deepClone, titleCase } from '../../../utilities'
import './ItemAttributeDropdown.scss';
import Form from 'react-bootstrap/Form';

const ItemAttributeDropdown = (props) => {
    const refSelect = useRef();
	const refReactSelect = useRef();

    const convertInitialValues = (initialValues) => {
        if (Array.isArray(initialValues)) {
            initialValues = initialValues.map( (value) => {
                return { value: value, label: value }
            });    
        } else {
            initialValues = { value: initialValues, label: initialValues }
        }
        return initialValues;
    }

    const { name, options, creatable, isMulti, onChange, loading, styles, required, id, hasValidated } = props;
    const [stateValues, setStateValues] = useState(props.values ? convertInitialValues(props.values) : []);
    const [stateInvalid, setStateInvalid] = useState(false);
    
    useEffect(() => {
		customValidity(stateValues, hasValidated !== undefined ? hasValidated : false);
    });

    const customValidity = (value, modifyInvalidState) => {
        modifyInvalidState = modifyInvalidState === true;
        if (!(refSelect && refSelect.current)) {
            return;
        }
        if (required && normalizeValue(value) === "") {
            refSelect.current.setCustomValidity("Please select a value.");
            if (modifyInvalidState && stateInvalid !== true) {
                setStateInvalid(true);
            }
        } else {
            refSelect.current.setCustomValidity(""); //Remove
            if (modifyInvalidState && stateInvalid !== false) {
                setStateInvalid(false);
            }
        }
    }


    const handleCreate = (inputValue) => {
        const valueObject = {value: inputValue, label: inputValue};
        options.push(valueObject);
        if(Array.isArray(stateValues)) {
            const newValues = deepClone(stateValues);
            newValues.push(valueObject);
            handleChange(newValues);
        } else {
            handleChange(valueObject);
        }
    } 

    const handleChange = (values) => {
        setStateValues(values);
        customValidity(values, true);
        if(Array.isArray(values)) {
            onChange(values.map( (value) => {
                return titleCase(value.value);
            }));   
        } else if (values) {
            onChange(titleCase(values.value));
        } else {
            onChange();
        }
    }

    const normalizeValue = (selection) => {
        if (Array.isArray(selection)) {
            if (selection.length) {
                return selection.map(keyPair => keyPair.value);
            }
        } else if (selection && typeof selection.value !== typeof undefined) {
            return selection.value;
        }
        return isMulti ? [] : "";
    }

    const getValidationStyle = () => {
        let validationStyle = 'dropdown';
        if (hasValidated && stateInvalid === false) {
            validationStyle += ' valid';
        } else if(stateInvalid === true) {
            validationStyle += ' invalid';
        }
        return validationStyle;
    }

    return (
        creatable ?
            <div className={getValidationStyle()}>
                <Form.Control
                    as="select"
                    ref={refSelect}
                    id={id}
                    data-testid={id}
                    disabled={loading}
                    required={required}
                    multiple={isMulti}
                    value={normalizeValue(stateValues)}
                    onChange={() => null}
                    onFocus={() => refReactSelect.current.focus()}
                >                   
                <option>{/* Empty option to align with react-select default being empty */}</option>
                {options.map(option => (
                    <option key={`${id}_${option.value}`} value={option.value}>
                        {option.label}
                    </option>
                ))}
                </Form.Control>
                <CreatableSelect
	    			ref={refReactSelect}
                    isClearable
                    onChange={handleChange}
                    onCreateOption={handleCreate}
                    options={options}
                    isDisabled={loading}
                    value={stateValues}
                    isMulti={isMulti}
                    styles={styles}
                    required={required}
                    classNamePrefix="slt"
                />
    			<Form.Control.Feedback type="invalid">
                        Please choose a type for this item.
                </Form.Control.Feedback>
		</div>
        :
        <Select
            isMulti={isMulti}
            name={name}
            options={options}
            className="basic-multi-select"
            isDisabled={loading}
            classNamePrefix="select"
            value={stateValues}
            onChange={handleChange}
            styles={styles}
        />
    );

}

export default ItemAttributeDropdown;
