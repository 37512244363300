import { WAVE_API_URL } from '../../libs/constants';
import axios from 'axios';

export const createInvoice = async (customerId, items) => {

    const createInvoiceGraphQLQuery = 'mutation ($input: InvoiceCreateInput!) { ' +
        'invoiceCreate(input: $input) {' +
        'didSucceed ' +
        'inputErrors {' +
        'message ' +
        'code ' +
        'path ' +
        '}' +
        'invoice {' +
        'id ' +
        'items { taxes { amount { value } salesTax { id } } }' +
        '}' +
        '}' +
        '}';

    const response = await axios
        .post(WAVE_API_URL,
            JSON.stringify({
                query: createInvoiceGraphQLQuery,
                variables: {
                    input: {
                        businessId: process.env.REACT_APP_WAVE_BUSINESS_ID,
                        customerId: customerId,
                        items
                    }
                }
            }),
            {
                headers: {
                    'Authorization': 'Bearer ' + process.env.REACT_APP_WAVE_TOKEN,
                    'Content-Type': 'application/json'
                }
            })
    if(response.status === 200) {
        return response.data.data.invoiceCreate.invoice.id;
    } else {
        console.log("Error sending to Wave:", response.statusText);
        return false;
    }
};

export const createItemForInvoice = (description, unitPrice, taxExempt) => {

    return {
        productId: process.env.REACT_APP_WAVE_PRODUCT_ID,
        description,
        unitPrice,
        quantity: 1,
        taxes: [
            { salesTaxId: taxExempt ? process.env.REACT_APP_WAVE_TAX_EXEMPT_ID : process.env.REACT_APP_WAVE_SALES_TAX_ID }
        ]
    };
}