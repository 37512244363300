import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import CreatableSelect from "react-select/creatable";
import CreateCustomerField from '../../Customers/CreateCustomerField/CreateCustomerField';
import useModal from '../../../hooks/useModal';
import ConfirmDeleteDialog from '../../ConfirmDeleteDialog/ConfirmDeleteDialog';
import './CreateEditShowOrder.scss';
import { cloneDeep } from 'lodash';
import ActorList from '../ActorList/ActorList';
import uuid from 'uuid';
import { useAppContext } from "../../../libs/contextLib";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import { createInvoice, createItemForInvoice } from '../../../libs/wave/createInvoice';
import { SendToWaveButton } from '../../../libs/buttons';


const CreateEditShowOrder = (props) => {

    const CONFIRM_DELETE_ACTOR_MESSAGE = 'Are you sure you want to delete this actor? This action cannot be undone.';
    const CONFIRM_SEND_TO_WAVE_MESSAGE = 'Are you sure you want to send this invoice to Wave?';

    const { shows, saveActor, saveShowOrderAttribute, isLoadingShowOrders } = useAppContext();
    const { showOrder, readOnly } = props;
    const [customer, setCustomer] = useState(showOrder ? showOrder.customer : '');
    const [showName, setShowName] = useState(showOrder ? { label: showOrder.showName, value: showOrder.showName } : '');
    const [quote, setQuote] = useState(showOrder ? showOrder.quote : 0);
    const [deliveryDate, setDeliveryDate] = useState(showOrder && showOrder.deliveryDate ? new Date(showOrder.deliveryDate) : '');
    const [showNameOptions, setShowNameOptions] = useState([]);
    const [actors, setActors] = useState(showOrder ? showOrder.actors : []);
    const [keyedActors, setKeyedActors] = useState(showOrder ? showOrder.keyedActors : []);
    const { isShowing: confirmDeleteShowing, toggle: toggleConfirmDelete } = useModal();
    const { isShowing: confirmSendToWaveShowing, toggle: toggleConfirmSendToWave } = useModal();
    const [actorKeyToBeDeleted, setActorKeyToBeDeleted] = useState(null);
    const [newActorCreated, setNewActorCreated] = useState(null);
    const [isSendingToWave, setIsSendingToWave] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        setShowNameOptions(convertInitialValues(shows));
    }, [shows]);

    useEffect(() => {
        if (showOrder) {
            if(showOrder.customer && showOrder.customer.id !== customer.id) {
                setCustomer(showOrder.customer);
            }
            if(showOrder.showName && showOrder.showName !== showName.label) {
                setShowName({ label: showOrder.showName, value: showOrder.showName });
            }
            setQuote(showOrder.quote);
            if (showOrder.deliveryDate) {
                let showOrderDateObject = new Date(showOrder.deliveryDate);
                let deliveryDateObject = new Date(deliveryDate);
                if(showOrderDateObject.toUTCString() !== deliveryDateObject.toUTCString()) {
                    setDeliveryDate(showOrderDateObject);
                }
            }
            if (showOrder.keyedActors) {
                setKeyedActors(showOrder.keyedActors);
            }
        }
    }, [showOrder]);

    const stringifiedKeyedActors = JSON.stringify(keyedActors);
    useEffect(() => {
        let subtotal = 0;
        Object.keys(keyedActors).forEach((actorKey) => {
            const actor = keyedActors[actorKey];
            actor.costumes.forEach((costume) => {
                subtotal += costume.price * 1;
            });
        });
        setQuote(subtotal);
    }, [stringifiedKeyedActors]);

    const validateOrder = () => {
        if (showName === '' || showName.label === undefined) {
            alert('Please enter a show name/order type to continue.');
            return false;
        }
        return true;
    };

    useEffect(() => {
        if(showOrder) {
            saveShowOrderAttribute(showOrder.id, 'quote', quote);
        }
    }, [quote]);

    useEffect(() => {
        if(showOrder) {
            saveShowOrderAttribute(showOrder.id, 'showName', showName.label);
        }
    }, [showName]);

    useEffect(() => {
        if(showOrder) {
            saveShowOrderAttribute(showOrder.id, 'customer', customer);
        }
    }, [customer]);
 
    useEffect(() => {
        if(showOrder) {
            let deliveryDateObject = new Date(deliveryDate);
            saveShowOrderAttribute(showOrder.id, 'deliveryDate', deliveryDateObject.toISOString());
        }
    }, [deliveryDate]);

    const convertInitialValues = (initialValues) => {
        return initialValues.map((value) => {
            return { value: value, label: value }
        });
    }

    const handleShowNameChange = useCallback((inputValue) => setShowName(inputValue), []);

    const handleShowNameCreate = useCallback(
        (inputValue) => {
            const newValue = { value: inputValue.toLowerCase(), label: inputValue };
            setShowNameOptions([...showNameOptions, newValue]);
            setShowName(newValue);
        },
        [showNameOptions]
    );

    const handleShowNameBlur = useCallback(
        (event) => {
            if (event.target.value) {
                handleShowNameCreate(event.target.value);
            }
        },
        [showNameOptions]
    );

    const openTagsWindow = (key) => {
        window.open('/orders/' + showOrder.id + '/actors/' + key + '/tags', "_blank");
    }

    const confirmRemoveActor = (key) => {
        setActorKeyToBeDeleted(key);
        toggleConfirmDelete();
    }

    const addActor = () => {
        const newActorId = uuid.v1();
        const newActor = {
            customer: { value: 'New Actor', id: null },
            measurements: [],
            roles: '',
            costumes: []
        };
        saveActor(showOrder.id, newActor, newActorId);
        setNewActorCreated(newActorId);
    }

    useEffect(() => {
        if (newActorCreated && showOrder.keyedActors && showOrder.keyedActors[newActorCreated]) {
            navigate('/orders/' + showOrder.id + '/' + newActorCreated);
            setNewActorCreated(null);
        }
    }, [newActorCreated, showOrder])

    const updateActor = (actor, key) => {
        const newActors = cloneDeep(keyedActors);
        newActors[key] = actor;
        setKeyedActors(newActors);
    };

    const removeActor = () => {
        const newActors = cloneDeep(keyedActors);
        delete newActors[actorKeyToBeDeleted];
        setKeyedActors(newActors);
    };

    const sendToWave = async () => {
        setIsSendingToWave(true);
        if (validateOrder() === false) {
            setIsSendingToWave(false);
        } else {
            try {
                const items = [];
                Object.keys(keyedActors).forEach((actorKey) => {
                    const actor = keyedActors[actorKey];
                    actor.costumes.forEach((costume) => {
                        const itemString = costume.items.map((item) => {
                            return item.description;
                        }).join(', ');
                        const costumeString = actor.customer.value + ' - ' + costume.description + ': ' + itemString;
                        items.push(createItemForInvoice(costumeString, costume.price, costume.taxExempt));
                    });
                });
                const response = await createInvoice(customer.id, items);
                if(response) {
                    alert('Successfully sent invoice to Wave');
                } else {
                    alert('Error sending to Wave');
                }
            } catch (error) {
                console.log(error);
            }
            setIsSendingToWave(false);
        }
    }

    const confirmSendToWave = (event) => {
        event.preventDefault();
        toggleConfirmSendToWave();
    }

    const addNewActorButton =
        <div className='editshoworder-add-button'>
            <Button
                data-testid="add-button"
                className='btn-success'
                onClick={addActor}
            >
                Add New Actor
            </Button>
        </div>;

    return (
        <LoadingOverlay
            active={isLoadingShowOrders}
            spinner
        >
            <ConfirmDeleteDialog
                isShowing={confirmDeleteShowing}
                hide={toggleConfirmDelete}
                onConfirm={removeActor}
                message={CONFIRM_DELETE_ACTOR_MESSAGE}

            />
            <ConfirmDeleteDialog
                isShowing={confirmSendToWaveShowing}
                hide={toggleConfirmSendToWave}
                onConfirm={sendToWave}
                message={CONFIRM_SEND_TO_WAVE_MESSAGE}
                buttonLabel={'Send To Wave'}
            />
            <div>
                <div className='show-name'>Show Name/ Order Type
                    <span className='show-name-example'>
                        (i.e. - "Guys And Dolls", "Renaissance Faire"):
                    </span>
                    <CreatableSelect
                        isClearable
                        onChange={handleShowNameChange}
                        onCreateOption={handleShowNameCreate}
                        onBlur={handleShowNameBlur}
                        options={showNameOptions}
                        value={showName}
                    />
                </div>
                <CreateCustomerField
                    customer={customer}
                    setCustomer={setCustomer}
                    readOnly={readOnly}
                />
                <div className='date-and-quote'>
                    <div className='delivery-date'>
                        Delivery Date: 
                        <DatePicker
                            selected={deliveryDate}
                            align="right"
                            placeholder="Delivery date"
                            onChange={setDeliveryDate}
                        />
                    </div>
                    <div className='quote'>
                        Quote: ${quote}
                        <SendToWaveButton isSendingToWave={isSendingToWave} sendToWave={confirmSendToWave} />
                        <br />
                    </div>
                </div>
                <hr />
                <div className='actor-section'>
                    <span>Actors:</span>
                    {/* <input
                        value={roleFilter}
                        placeholder="Role Search"
                        onChange={setRoleFilter}
                        className='role-search'
                    /> */}
                    {addNewActorButton}
                    <ActorList
                        handleUpdate={updateActor}
                        handleDelete={confirmRemoveActor}
                        handleTags={openTagsWindow}
                        showOrder={showOrder}
                        actors={keyedActors}
                    />
                    {Object.keys(keyedActors).length > 0 ? addNewActorButton : ''}
                </div>
            </div>
        </LoadingOverlay>
    );
}

export default CreateEditShowOrder;