import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import ShowOrderList from '../ShowOrderList/ShowOrderList';
import './ManageShowOrdersPage.scss';
import { useAppContext } from "../../../libs/contextLib";
import { useNavigate } from 'react-router-dom';

const ManageShowOrdersPage = (props) => {

    const { showOrders, createShowOrder, isLoadingShowOrders } = useAppContext();
    const [newShowOrderCreated, setNewShowOrderCreated] = useState(null);

    const navigate = useNavigate();

    const createNewShowOrder = async () => {
        const newShowOrder = {
            showName: 'New Order',
            customer: '',
            deliveryDate: '',
            quote: 0,
            actors: [],
            invoiceId: null
        };
        const showOrderId = await createShowOrder(newShowOrder);
        setNewShowOrderCreated(showOrderId);
    };

    useEffect(() => {
        if(newShowOrderCreated) {
            navigate('/orders/' + newShowOrderCreated);
            setNewShowOrderCreated(null);
        }
    }, [newShowOrderCreated])

    return (
        <>
            <div className='manageshoworders-add-button'>
                <Button
                    data-testid="show-order-add-button"
                    className='btn-success'
                    onClick={createNewShowOrder}
                    disabled={isLoadingShowOrders}
                >
                    {isLoadingShowOrders ? 'Working...' : 'Add New Order'}
                </Button>
            </div>
            <ShowOrderList
                columns={{
                    showName: 'Show Name',
                    customer: 'Customer',
                    quote: 'Quote',
                    deliveryDate: 'Delivery Date'
                }}
                data={showOrders}
            />
        </>
    );

}

export default ManageShowOrdersPage;