import React, { useState, useEffect, useCallback } from 'react';
import AddEditCostume from '../../Costumes/AddEditCostume/AddEditCostume';
import { Accordion, Card, Button } from 'react-bootstrap';
import { ContextAwareToggle } from '../../../utilities';
import ItemDialog from '../../Inventory/ItemDialog/ItemDialog';
import $ from 'jquery';

import './CostumeList.scss';

const CostumeList = (props) => {

    const { costumes, readOnly, showOrder, actorKey, handleDelete, handleUpdate } = props;
    const [activeKey, setActiveKey] = useState(null);
    const [previousLength, setPreviousLength] = useState(costumes.length);
    const [isItemDialogShowing, setIsItemDialogShowing] = useState(false);
    const [selectItemFunction, setSelectItemFunction] = useState(() => {});
    const [costumeTagItemFilter, setCostumeTagItemFilter] = useState(null);

    useEffect(() => {
        var panel = $('.costume-collapse-' + showOrder.id + '-' + actorKey + '-' + activeKey);
        if (panel.offset()) {
            $('html, body').animate({
                scrollTop: panel.parent().offset().top
            }, 250);
        }
    }, [activeKey, actorKey, showOrder.id]);

    const openAccordion = useCallback((eventKey) => {
        if (activeKey === eventKey) {
            setActiveKey(null);
        } else {
            setActiveKey(eventKey);
        }
    }, [activeKey]);

    useEffect(() => {
        if (costumes.length > previousLength) {
            openAccordion(costumes.length - 1);
        } else if (costumes.length < previousLength) {
            setActiveKey(null);
        }
        setPreviousLength(costumes.length);
    }, [costumes.length, openAccordion, previousLength]);

    if (!costumes) {
        return '';
    }

    return (
        <>
            <ItemDialog
                isShowing={isItemDialogShowing}
                hide={() => setIsItemDialogShowing(!isItemDialogShowing)}
                selectItem={selectItemFunction}
                initialFilters={costumeTagItemFilter}
            />
            <Accordion activeKey={activeKey}>
                {costumes.map((costume, index) => {
                    return (
                        <Card key={index}>
                            <ContextAwareToggle eventKey={index} callback={openAccordion} className='costumelist-header'>
                                <Card.Header>
                                    <div>
                                        <div className='costumelist-description'>
                                            {costume.description
                                                ? costume.description
                                                : 'New Costume'
                                            }
                                        </div>
                                    </div>
                                </Card.Header>
                            </ContextAwareToggle>
                            <Accordion.Collapse className={'costume-collapse-' + showOrder.id + '-' + actorKey + '-' + index} eventKey={index}>
                                <Card.Body className='costumelist-body'>
                                    <div className='costumelist-buttons'>
                                        <Button
                                            className='btn-danger'
                                            onClick={() => handleDelete(index)}
                                            data-testid='delete-button'
                                        >
                                            Remove Costume
                                        </Button>
                                    </div>
                                    <AddEditCostume
                                        onSave={handleUpdate}
                                        costume={costume}
                                        costumeIndex={index}
                                        readOnly={readOnly}
                                        showName={showOrder.showName}
                                        setIsItemDialogShowing={setIsItemDialogShowing}
                                        setSelectItemFunction={setSelectItemFunction}
                                        setCostumeTagItemFilter={(tags) => setCostumeTagItemFilter({'tags': tags})}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )
                })}
            </Accordion>
        </>
    );
};

export default CostumeList;