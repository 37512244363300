import { useEffect, useState } from "react";
import axios from 'axios';

export const useItemAttributeApi = () => {
    const [attributes, setAttributes] = useState([]);
    const [isLoadingAttributes, setIsLoadingAttributes] = useState(true);

    useEffect(() => {
        const attributeTypes = ['itemType',
        'colors',
        'periods',
        'tags',
        'itemCondition'
    ];

        let fetchAttributes = async () => {
            setIsLoadingAttributes(true);
            const response = await axios.get(process.env.REACT_APP_API_URL + '/attributes');
            const newAttributes = {};
            attributeTypes.forEach((attribute) => {
                if (response.data[attribute]) {
                    newAttributes[attribute] = response.data[attribute].map(
                            value => {
                                return { 'value': value, 'label': value };
                            }
                        )
                    }
                    else {
                        newAttributes[attribute] = [];
                    }
            });
            setAttributes(newAttributes);
            setIsLoadingAttributes(true);
        };
        
        fetchAttributes();
    }, []);

    return [attributes, isLoadingAttributes];
}

