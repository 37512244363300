import React, { useState, useEffect, useCallback } from 'react';
import './AddEditCostume.scss';
import { Button } from 'react-bootstrap';
import ImageDisplay from "../../Inventory/ImageDisplay/ImageDisplay";
import { deleteIcon, editIcon } from '../../../libs/icons';
import { useAppContext } from '../../../libs/contextLib';
import useModal from '../../../hooks/useModal';
import ConfirmDeleteDialog from '../../ConfirmDeleteDialog/ConfirmDeleteDialog';
import CreateEditItemDialog from '../../Inventory/CreateEditItemDialog/CreateEditItemDialog';

const AddEditCostume = (props) => {

    const CONFIRM_DELETE_ITEM_MESSAGE = "Are you sure you want to delete this item? This action cannot be undone.";
    const { costume, onSave, costumeIndex, readOnly, setIsItemDialogShowing, setSelectItemFunction, setCostumeTagItemFilter, showName } = props;
    const { createItem, items, attributes } = useAppContext();

    const [itemList, setItemList] = useState(costume ? costume.items : []);
    const [description, setDescription] = useState(costume ? costume.description : '');
    const [price, setPrice] = useState(costume ? costume.price : 0);
    const [taxExempt, setTaxExempt] = useState(costume ? costume.taxExempt : false);
    const [quickItemDescription, setQuickItemDescription] = useState('');
    const [indexToBeDeleted, setIndexToBeDeleted] = useState(null);
    const { isShowing: confirmDeleteShowing, toggle: toggleConfirmDelete } = useModal();
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [itemToEdit, setItemToEdit] = useState(null);


    const saveCostume = () => {
        const submittedCostume = {
            description,
            price,
            taxExempt,
            items: itemList
        };
        onSave(submittedCostume, costumeIndex);
    };

    const stringifiedItemList = JSON.stringify(itemList);
    useEffect(() => {
        saveCostume();
    }, [stringifiedItemList, price, taxExempt, costumeIndex, itemList]);

    const isItemAlreadyPresent = (itemId) => {
        const foundItems = itemList.filter(item => item.id === itemId);
        return foundItems.length;
    };

    const selectItem = (newItem) => {
        if (!newItem) {
            return '';
        };

        if (!isItemAlreadyPresent(newItem.id)) {
            setItemList([...itemList, newItem]);
        }
        setIsItemDialogShowing(false);
    };

    const searchItems = () => {
        setSelectItemFunction(() => { return selectItem });
        setCostumeTagItemFilter([description])
        setIsItemDialogShowing(true);
    };

    const removeItem = (index) => {
        setItemList(itemList.filter((item, i) => i !== index));
    };

    const quickAddItem = async (event) => {
        const item = {
            description: quickItemDescription,
            itemType: '',
            colors: [],
            periods: [],
            tags: [costume.description, showName],
            images: [],
            sizing: []
        }

        const itemId = await createItem(item);
        const shortItem = {
            description: item.description,
            id: itemId,
            image: ''
        }
        selectItem(shortItem);
        setQuickItemDescription('');
    };

    const handleQuickAddKeyPress = (event) => {
        if (event.key === 'Enter') {
            quickAddItem(event);
        }
    }

    const confirmDelete = (index) => {
        setIndexToBeDeleted(index);
        toggleConfirmDelete();
    }

    const editItem = useCallback((itemId) => {
        const foundItem = items.find((item) => item.id == itemId );
        if(foundItem) {
            setItemToEdit(foundItem);
            setEditDialogVisible(true);
        } else {
            alert("Item not found, cannot edit");
        }
    }, [items]);

    useEffect(() => {
        if(itemToEdit) {
            const itemIndex = itemList.findIndex((item) => item.id == itemToEdit.id);
            const foundItem = items.find((item) => item.id == itemToEdit.id);
            if(itemIndex != -1 && foundItem) {
                const newItems = itemList.map((item, index) => {
                    if(itemIndex == index) { 
                        return {
                            description: foundItem.description,
                            id: foundItem.id,
                            image: foundItem.images.length > 0 ? foundItem.images[0] : ''
                        };
                    } else {
                        return item;
                    }
                });
                setItemList(newItems);
            }
            setItemToEdit(null);
        }
    }, [items]);

    const itemsDiv =
        (<div>
            <ConfirmDeleteDialog
                isShowing={confirmDeleteShowing}
                hide={toggleConfirmDelete}
                onConfirm={() => { removeItem(indexToBeDeleted) }}
                message={CONFIRM_DELETE_ITEM_MESSAGE}

            />
            <div className='items-container'>
                {itemList ? itemList.map((item, index) =>
                    <div key={index} className='item-box'>
                        {item.image
                            ?
                            <ImageDisplay
                                filename={item.image}
                                description={item.description} />
                            : item.description}
                        {!readOnly ?
                            <div className='item-remove-button-div'>
                                <Button className="edit-item-button btn-info" onClick={() => { editItem(item.id); }}>
                                    {editIcon}
                                </Button>
                                <Button className="remove-item-button btn-danger" onClick={() => confirmDelete(index)}>
                                    {deleteIcon}
                                </Button>
                            </div>
                            : ''}
                    </div>
                ) : ''}
            </div>
            {!readOnly
                ?
                <div className='add-item-div'>
                    <div className='quick-item-description-div'>
                        <input
                            value={quickItemDescription}
                            placeholder="New Item Description"
                            onChange={(e) => setQuickItemDescription(e.target.value)}
                            disabled={readOnly}
                            className='quick-item-description-input'
                            onKeyPress={handleQuickAddKeyPress}
                        />
                    </div>
                    <div className='item-search'><Button className="btn-success" onClick={quickAddItem}>
                        Quick Add
                    </Button>
                        <Button className="btn-success" onClick={searchItems}>
                            Search
                        </Button>
                    </div>
                </div>
                : ''}
            <hr />
        </div>);

    return (
        <>
            <div className='description-label'>Costume Name
                <input
                    value={description}
                    placeholder="Description"
                    onChange={(e) => setDescription(e.target.value)}
                    onBlur={saveCostume}
                    disabled={readOnly}
                    className='description-input'
                />
            </div>
            <div className='price-controls'>
                Price:
                <input
                    prepend="$"
                    placeholder="Price"
                    className="price-input"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    disabled={readOnly}
                    size='large'
                />
            </div>
            {itemsDiv}
            <CreateEditItemDialog
                updateOrCreate={'Update'}
                isShowing={editDialogVisible}
                setCreateDialogVisible={setEditDialogVisible}
                setNewlyCreatedItemId={() => {}}
                setItemToEdit={()=>{}}
                types={attributes?.itemType}
                colors={attributes?.colors}
                periods={attributes?.periods}
                tags={attributes?.tags}
                conditions={attributes?.itemCondition}
                itemToEdit={itemToEdit}
            />
        </>
    );
}

export default AddEditCostume;