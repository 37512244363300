import React from 'react';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import './Breadcrumbs.scss';
import { Breadcrumb } from 'react-bootstrap';
import { useAppContext } from "../../libs/contextLib";
import { Link } from 'react-router-dom';

const Breadcrumbs = () => {
    const { showOrders, isLoadingShowOrders } = useAppContext();

    const DynamicShowOrderBreadcrumb = ({ match }) => {
        const showOrderById = showOrders.find((showOrder) => showOrder.id == match.params.showOrderId);
        return showOrderById ? showOrderById.showName : match.params.showOrderId;
    };
    
    const DynamicActorBreadcrumb = ({ match }) => {
        let actor = match.params.actorId;
        const showOrderById = showOrders.find((showOrder) => showOrder.id == match.params.showOrderId);
        if (showOrderById && showOrderById.keyedActors) {
            const actorObject = showOrderById.keyedActors[match.params.actorId];
            if(actorObject) {
                actor = actorObject.customer.value;
            }
        }
        return actor;
    };
        
    const routes = [
        { path: '/orders/:showOrderId', breadcrumb: DynamicShowOrderBreadcrumb },
        { path: '/orders/:showOrderId/:actorId', breadcrumb: DynamicActorBreadcrumb },
    ];

    const breadcrumbs = useReactRouterBreadcrumbs(routes);
  
    return (
        <>
      <Breadcrumb>
        {breadcrumbs.map(({ match, breadcrumb }) => <Breadcrumb.Item key={match.pathname} linkAs={Link} linkProps={{ to: match.pathname }}>{breadcrumb}</Breadcrumb.Item>)}
      </Breadcrumb>
      <hr className='hr-breadcrumb' />
      </>
    );
}

export default Breadcrumbs;