import React, { useRef, useEffect } from 'react';
import { Form, Button } from "react-bootstrap";
import './SizingTab.scss';
import { deleteIcon, plusCircleIcon } from '../../../../../libs/icons';
import { titleCase } from '../../../../../utilities';

const SizingTab = (props) => {

    const { sizing, setSizing } = props;

    const lastInputReference = useRef(null);
    
    const sizingLength = sizing ? sizing.length : 0;

    useEffect(() => {
        if(lastInputReference.current) {
            lastInputReference.current.focus();
        }
    }, [sizingLength]);

    const handleAddClick = (event) => {
        event.preventDefault();
        appendInput();
    };

    const appendInput = () => {
        const blankSizing = { label: '', value: '' };
        if (!sizing) {
            setSizing([blankSizing]);
        } else {
            setSizing(sizing.concat([blankSizing]));
        }
    }

    const changeSizingLabel = (index, newLabel) => {
        const newSizing = [...sizing];
        newSizing[index].label = newLabel; 
        setSizing(newSizing);
    }

    const changeSizingValue = (index, newValue) => {
        const newSizing = [...sizing];
        newSizing[index].value = newValue;
        setSizing(newSizing);
    }

    const removeInput = (indexToRemove) => {
        setSizing(sizing.filter((item,index) => index !== indexToRemove));
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            appendInput();
        }
    }

    return (
        <div className='sizing-table'>
            <div id="dynamicInput">
                {sizing ? sizing.map( (input, index) =>
                    <div key={index} className='sizing-row'>
                    <Form.Control
                        onChange={ (event) => changeSizingLabel(index, event.target.value)}
                        value={titleCase(input.label)}
                        placeholder="Sizing Type"
                        type="text"
                        className="sizing-label"
                        ref={index === sizing.length-1 ? lastInputReference : null}
                        onKeyPress={handleKeyPress}
                    />
                    <Form.Control
                        onChange={ (event) => changeSizingValue(index, event.target.value)}
                        value={input.value}
                        placeholder="Size Value"
                        type="text"
                        className="sizing-value"
                        onKeyPress={handleKeyPress}
                    />
                    <Button className="btn-danger remove-sizing-button" onClick={() => removeInput(index)}>
                        {deleteIcon}
                    </Button>
                    </div>
                ) : ''}
            </div>
            <Button data-testid="add-sizing-button" className="btn-success add-sizing-button" onClick={ handleAddClick }>
                    {plusCircleIcon}
            </Button>
        </div>
    );
}

export default SizingTab;