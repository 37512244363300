import React, { useState } from 'react';
import CreatableSelect from "react-select/creatable";
import CreateCustomerDialog from '../../Customers/CreateCustomerDialog/CreateCustomerDialog';
import './CreateCustomerField.scss';
import { useAppContext } from "../../../libs/contextLib";
import { useEffect } from 'react';

const CreateCustomerField = (props) => {

    const { customer, setCustomer, readOnly } = props;
    const [isCreateCustomerShowing, setIsCreateCustomerShowing] = useState(false);
    const { customers, fetchCustomers } = useAppContext();

    useEffect(() => {
        if (customer === undefined) {
            setCustomer({ id: null, value: '' });
        }
    }, [customer]);

    const toggleCreateCustomerDialog = () => {
        setIsCreateCustomerShowing(!isCreateCustomerShowing);
    }

    const handleCreateCustomer = () => {
        fetchCustomers();
    }

    const handleChangeCustomer = (newCustomer) => {
        setCustomer({
            id: newCustomer.id ? newCustomer.id : null,
            value: newCustomer.label ?? ''
        });
    };

    const onCreate = (newCustomer) => {
        setCustomer({
            id: null,
            value: newCustomer
        });
        setIsCreateCustomerShowing(true);
    }

    const onBlur = (event) => {
        if(event.target.value) {
            onCreate(event.target.value);
        }
    }

    const convertCustomer = (customer) => {
        return {
            id: customer.id,
            label: customer.value
        };
    }

    const convertedCustomers = customers.map((customer, index) => {
        return convertCustomer(customer);
    });

    return (
        <div>
            <CreateCustomerDialog
                isShowing={isCreateCustomerShowing}
                hide={toggleCreateCustomerDialog}
                onConfirm={handleCreateCustomer}
                customerName={customer?.value}
                setCustomerName={handleChangeCustomer}
                setCustomer={setCustomer}
            />
            <div className='customer-row'>
                <div>Customer:</div>
                <CreatableSelect
                    onChange={handleChangeCustomer}
                    options={convertedCustomers}
                    value={convertCustomer(customer)}
                    onCreateOption={onCreate}
                    onBlur={onBlur}
                />
            </div>
        </div>
    );
}

export default CreateCustomerField;