import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AddEditActor from '../../components/ShowOrders/AddEditActor/AddEditActor';
import { useAppContext } from "../../libs/contextLib";

const CreateEditActorPage = (props) => {

    const { showOrderId, actorKey } = useParams();
    const { showOrders, loadShowOrder, saveActor } = useAppContext();
    const [actor, setActor] = useState(null);
    const [showOrder, setShowOrder] = useState(null);

    useEffect(() => {
        const foundShowOrder = showOrders.find((order) => order.id === showOrderId);
        if (foundShowOrder) {
            setShowOrder(foundShowOrder);
            if (foundShowOrder && foundShowOrder.keyedActors && Object.keys(foundShowOrder.keyedActors).length > 0) {
                if (actorKey in foundShowOrder.keyedActors) {
                    setActor(foundShowOrder.keyedActors[actorKey]);
                }
            }
        }
    }, [showOrders]);

    useEffect(() => {
        const loadOrder = async () => {
            if (showOrder && (!showOrder.keyedActors || Object.keys(showOrder.keyedActors).length <= 0)) {
                const loadedOrder = await loadShowOrder(showOrderId);
                setShowOrder(loadedOrder);
                if (actorKey in loadedOrder.keyedActors) {
                    setActor(loadedOrder.keyedActors[actorKey]);
                }
            }
        };
        loadOrder();
    }, [showOrder]);

    return (showOrder && showOrder.keyedActors && actor) ?
        <AddEditActor
            onSave={saveActor}
            actor={actor}
            actorKey={actorKey}
            showOrder={showOrder}
        /> : '';
};

export default CreateEditActorPage;