import { WAVE_API_URL } from '../../libs/constants';
import axios from 'axios';

export const createCustomer = async (customerName, email, phone) => {

    const createCustomerGraphQLQuery = 'mutation ($input: CustomerCreateInput!) { ' +
        ' customerCreate(input: $input) { ' +
        '   didSucceed ' +
        '   inputErrors { ' +
        '     code ' +
        '     message ' +
        '     path ' +
        '   } ' +
        '   customer { ' +
        '     id ' +
        '     name ' +
        '     firstName ' +
        '     lastName ' +
        '     email ' +
        '     phone ' +
        '   } ' +
        '  } ' +
        '}';

    const response = await axios
        .post(WAVE_API_URL,
            JSON.stringify({
                query: createCustomerGraphQLQuery,
                variables: {
                    input: {
                        businessId: process.env.REACT_APP_WAVE_BUSINESS_ID,
                        name: customerName,
                        email: email,
                        phone: phone
                    }
                }
            }),
            {
                headers: {
                    'Authorization': 'Bearer ' + process.env.REACT_APP_WAVE_TOKEN,
                    'Content-Type': 'application/json'
                }
            });

    return {
        id: response.data.data.customerCreate.customer.id,
        value: customerName
    };
};
