import React from 'react';
import { Button } from 'react-bootstrap';

export const SaveButton = (props) => {

    const { isSaving, buttonText, type, onClick } = props;

    return (
        <Button
            className="btn btn-primary action-btn"
            type={type ? type : "submit"}
            disabled={isSaving}
            onClick={onClick ? onClick : ''}
        >
            {isSaving ? 'Saving' : buttonText}
        </Button>);
}

export const SendToWaveButton = (props) => {

    const { isSendingToWave, sendToWave } = props;

    return (
        <Button
            className="btn send-to-wave-btn action-btn"
            type="button"
            disabled={isSendingToWave}
            onClick={sendToWave}
        >
            {isSendingToWave ? 'Sending...' : 'Send to Wave'}
        </Button>
    );
}

export const CancelButton = (props) => {

    const { onCancel } = props;

    return (
        <Button
            type="button"
            className="btn btn-danger action-btn"
            onClick={onCancel}
        >
            Cancel
        </Button>
    );
}