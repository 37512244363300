import React from 'react';
import { Table } from 'react-bootstrap';
import logo from '../../../assets/a_cut_above_logo_bw_with_address.png';
import { stripParensFromCustomerWhenEmailIsEmpty, stripParentheses } from '../../../utilities';

import './CostumeSheet.scss';

const CostumeSheet = (props) => {

    const { showOrder, actor } = props;

    if (!showOrder || !actor) {
        return '';
    }

    return (
        <>
            <div className='costumesheet-header'>
                <div className='costumesheet-logo-div'>
                    <img src={logo} className='costumesheet-logo' alt='A Cut Above Logo'/>
                </div>
                <div className='costumesheet-info-block'>
                    <div className='costumesheet-show-title'>
                        {showOrder?.showName}
                    </div>
                    <div className='costumesheet-customer-name'>
                        {stripParentheses(showOrder?.customer?.value)}
                    </div>
                    <div className='costumesheet-actor-name'>
                        {stripParensFromCustomerWhenEmailIsEmpty(actor.customer.value)}
                    </div>
                    <div className='costumesheet-roles'>
                        {actor.roles}
                    </div>
                </div>
            </div>
            <div className='costumesheet-costumes'>
                <Table striped bordered hover className='costumesheet-costume-table'>
                    <thead>
                        <tr className='costumesheet-table-print-header'>
                            <th className='costumesheet-table-print-header-showinfo'>
                                    {showOrder?.showName}<br />
                                    {stripParentheses(showOrder?.customer?.value)}
                            </th>
                            <th className='costumesheet-table-print-header-actorinfo'>
                                {stripParentheses(actor.customer.value)}<br />
                                {actor.roles}
                            </th>
                        </tr>
                        <tr>
                            <th>Costume</th>
                            <th className='costumesheet-costume-pieces'>Pieces</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.isArray(actor.costumes)
                            ?
                            actor.costumes.map((costume, index) => {
                                return (
                                    <tr className='costumesheet-costume'>
                                        <td className='costumesheet-costume-name'>
                                            {costume.description}
                                        </td>
                                        <td className='costumesheet-costume-pieces'>
                                            {costume.items.map((piece, pieceIndex) => {
                                                return (
                                                    <div>
                                                        <div className='costumesheet-costume-piece'>
                                                            {piece.description}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </td>
                                    </tr>
                                )
                            })
                            : ''}
                    </tbody>
                </Table>
                <div className='costumesheet-footer'></div>
            </div>
        </>
    )
};

export default CostumeSheet;