import React from 'react';
import { Modal } from 'react-bootstrap';
import './ItemDialog.scss';
import ManageInventoryPage from '../ManageInventoryPage/ManageInventoryPage';

const ItemDialog = ({ isShowing, hide, selectItem, initialFilters }) => {

    return (
        <Modal
            show={isShowing}
            onHide={hide}
        >
            <Modal.Dialog
                title="Add Item To Costume"
                size="full"
            >
                <Modal.Body>
                    <ManageInventoryPage
                        selectItem={selectItem}
                        initialFilters={initialFilters}
                    />
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    );
}

export default ItemDialog;