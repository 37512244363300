import { useEffect, useState, useCallback } from "react";
import axios from 'axios';
import { WAVE_API_URL } from '../libs/constants';

export const useCustomerApi = () => {
    const [customers, setCustomers] = useState([]);
    const [isLoadingCustomers, setIsLoadingCustomers] = useState(true);

    const customerGraphQLQuery = 'query { ' +
        ' business(id: "' + process.env.REACT_APP_WAVE_BUSINESS_ID + '") {' +
        '    id ' +
        '    customers(page: 1, pageSize: 1000, sort: [NAME_ASC]) { ' +
        '        pageInfo { ' +
        '            currentPage ' +
        '            totalPages ' +
        '            totalCount ' +
        '        } ' +
        '        edges { ' +
        '            node { ' +
        '                id ' +
        '                name ' +
        '                email ' +
        '            } ' +
        '        } ' +
        '    } ' +
        '} ' +
        '}';

    const fetchCustomers = async () => {
        setIsLoadingCustomers(true);
        await axios
            .post(WAVE_API_URL,
                JSON.stringify({
                    query: customerGraphQLQuery,
                    variables: {}
                }),
                {
                    headers: {
                        'Authorization': 'Bearer ' + process.env.REACT_APP_WAVE_TOKEN,
                        'Content-Type': 'application/json'
                    }
                })
            .then(response => {
                setCustomers(response.data.data.business.customers.edges.map( (customer) => {
                    return { 
                        'id': customer.node.id,
                        'value': customer.node.name + ' (' + customer.node.email + ')'
                    }
                }));
                setIsLoadingCustomers(false);
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchCustomers();
    }, []);

    return [customers, isLoadingCustomers, { setCustomers, fetchCustomers }];
}

