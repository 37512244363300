import React, { useState, useEffect, useCallback, useRef } from 'react';
import CreateCustomerField from '../../Customers/CreateCustomerField/CreateCustomerField';
import SizingTab from '../../Inventory/CreateEditItem/Tabs/SizingTab/SizingTab';
import CostumeList from '../../Costumes/CostumeList/CostumeList';
import { convertCharactersArrayToCostumes, convertCharactersArrayToRoleString, convertItemsToSelectBoxValueAndLabel, deepClone } from '../../../utilities';
import useModal from '../../../hooks/useModal';
import ConfirmDeleteDialog from '../../ConfirmDeleteDialog/ConfirmDeleteDialog';
import { Button } from 'react-bootstrap';

import './AddEditActor.scss';


const AddEditActor = (props) => {

    const CONFIRM_DELETE_COSTUME_MESSAGE = 'Are you sure you want to delete this costume? This action cannot be undone.';

    const { actor, actorKey, showOrder, onSave, readOnly } = props;
    const [customer, setCustomer] = useState(actor ? actor.customer : '');
    const [measurements, setMeasurements] = useState(actor ? actor.measurements : null);
    const [costumeIndexToBeDeleted, setCostumeIndexToBeDeleted] = useState(null);
    const { isShowing: confirmDeleteShowing, toggle: toggleConfirmDelete } = useModal();

    const addCostumeReference = useRef(null);

    useEffect(() => {
        addCostumeReference.current.focus();
    }, []);

    const getRolesFromActor = useCallback(() => {
        if (actor) {
            return (
                actor.characters
                    ? convertCharactersArrayToRoleString(actor.characters)
                    : actor.roles
            )
        }
        return '';
    }, [actor]);

    const getCostumesFromActor = useCallback(() => {
        if (actor) {
            return (
                actor.characters
                    ? convertCharactersArrayToCostumes(actor.characters)
                    : actor.costumes
            )
        }
        return [];
    }, [actor]);

    const [roles, setRoles] = useState(getRolesFromActor());
    const [costumes, setCostumes] = useState(getCostumesFromActor());

    // useEffect(() => {
    //     setRoles(getRolesFromActor());
    //     setCostumes(getCostumesFromActor());
    //     setMeasurements(actor ? actor.measurements : null);
    //     setCustomer(actor ? actor.customer : '');
    // }, [actor, getRolesFromActor, getCostumesFromActor]);

    const stringifiedMeasurements = JSON.stringify(measurements);
    const stringifiedCostumes = JSON.stringify(costumes);
    const saveActor = useCallback(() => {
        const submittedActor = {
            customer,
            measurements,
            roles,
            costumes
        };
        onSave(showOrder.id, submittedActor, actorKey);
    }, [actorKey, customer, measurements, roles, costumes, onSave]);

    useEffect(() => {
        // if (customer?.id !== null) {
            saveActor();
        // }
    }, [customer, stringifiedCostumes, stringifiedMeasurements, roles, saveActor]);

    const confirmRemoveCostume = (costumeIndex) => {
        setCostumeIndexToBeDeleted(costumeIndex);
        toggleConfirmDelete();
    }

    const addCostume = () => {
        const newCostume = {
            description: '',
            price: 0,
            taxExempt: false,
            items: []
        };
        setCostumes([...costumes, newCostume]);
    };

    const updateCostume = (costume, index) => {
        const updatedCostumes = deepClone(costumes);
        updatedCostumes[index] = costume;
        setCostumes(updatedCostumes);
    };

    const removeCostume = () => {
        setCostumes(costumes.filter((costume, i) => i !== costumeIndexToBeDeleted));
    };

    return (<div>
        <ConfirmDeleteDialog
            isShowing={confirmDeleteShowing}
            hide={toggleConfirmDelete}
            onConfirm={removeCostume}
            message={CONFIRM_DELETE_COSTUME_MESSAGE}

        />
        <CreateCustomerField
            customer={customer}
            setCustomer={setCustomer}
            readOnly={readOnly}
        />
        <div className='roles-section'>
            Role(s):
            <input
                value={roles}
                placeholder="Role(s)"
                onChange={(e) => setRoles(e.target.value)}
                className='roles-input'
            />
        </div>
        <div className='measurements-section'>
            Measurements:
            <SizingTab
                sizing={measurements}
                setSizing={setMeasurements}
            />
        </div>
        <div className='costumes-section'>
            Costumes:
            <CostumeList
                costumes={costumes}
                readOnly={false}
                handleDelete={confirmRemoveCostume}
                handleUpdate={updateCostume}
                showOrder={showOrder}
                actorKey={actorKey}
            />
        </div>
        <div className='editactor-add-button'>
            <Button data-testid="add-button" className='btn-success' onClick={addCostume} ref={addCostumeReference}>
                Add New Costume
            </Button>
        </div>
    </div>
    );

}

export default AddEditActor;