import React, { useState } from "react";
import { Button, FormGroup, FormControl, FormLabel, NavItem } from "react-bootstrap";
import "./ForgotPassword.scss";
import { Amplify, Auth } from "aws-amplify";
import { useAppContext } from "../../../libs/contextLib";

const ForgotPassword = () => {
    const { setForgotPasswordClicked } = useAppContext();

    const [email, setEmail] = useState("");
    const [requestCodeSubmitted, setRequestCodeSubmitted] = useState(false);
    const [verificationCode, setVerificationCode] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    
    const validateResetPasswordForm = () => {
        return (
            newPassword === confirmPassword
        );
    }

    const validateRequestCodeForm = () => {
        return email.length > 0;
    }

    const handleReset = async(event) => {
        event.preventDefault();
      
        try {
          await Auth.forgotPasswordSubmit(
              email,
              verificationCode,
              newPassword);
          alert("Password reset. Returning to login");
          setForgotPasswordClicked(false);
        } catch (e) {
          alert(e.message);
        }
    }

    const handleRequestCode = async(event) => {
        event.preventDefault();

        try {
            await Auth.forgotPassword(email);
            setRequestCodeSubmitted(true);
        } catch (e) {
            alert(e.message);
        }
    }

    Amplify.configure({
        Auth: {
            region: process.env.REACT_APP_REGION,
            userPoolId: process.env.REACT_APP_USER_POOL_ID,
            userPoolWebClientId: process.env.REACT_APP_POOL_WEB_CLIENT_ID,
        }
    });

    const renderCodeEntryForm = () => {
        return (
            <div className="ForgotPassword">
                <h2>Reset Password</h2>
                <p>A verification code has been sent to this e-mail address.  Please enter the verification code and your new password and click 'Reset'</p>
                <form onSubmit={handleReset}>
                    <FormGroup controlId="email" bsSize="large">
                    <FormLabel>Verification Code</FormLabel>
                    <FormControl
                        autoFocus
                        type="text"
                        value={verificationCode}
                        onChange={e => setVerificationCode(e.target.value)}
                    />
                    </FormGroup>
                    <FormGroup controlId="newPassword" bsSize="large">
                    <FormLabel>New Password</FormLabel>
                    <FormControl
                        autoFocus
                        type="password"
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                    />
                    </FormGroup>
                    <FormGroup controlId="confirmPassword" bsSize="large">
                    <FormLabel>Confirm New Password</FormLabel>
                    <FormControl
                        autoFocus
                        type="password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                    />
                    </FormGroup>
                    <Button block bsSize="large" disabled={!validateResetPasswordForm()} type="submit">
                    Reset Password
                    </Button>
                </form>
            </div>
        );
    }

    const renderRequestCodeForm = () => {
        return (
            <div className="ForgotPassword">
                <h2>Forgot Password</h2>
                <form onSubmit={handleRequestCode}>
                    <FormGroup controlId="email" bsSize="large">
                    <FormLabel>Email</FormLabel>
                    <FormControl
                        autoFocus
                        type="text"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    />
                    </FormGroup>
                    <Button block bsSize="large" disabled={!validateRequestCodeForm()} type="submit">
                    Request Reset Password Code
                    </Button>
                </form>
            </div>
        );
    }
    
    return (
        <div>
        {requestCodeSubmitted 
        ? renderCodeEntryForm()
        : renderRequestCodeForm()
        }
        <NavItem 
          className='top-nav-bar'
          onClick={() => { setForgotPasswordClicked(false)}}
        >
            Return to Login
        </NavItem>
        </div>
    );
}

export default ForgotPassword;