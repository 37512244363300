import React from 'react';
import CreateEditItem from '../CreateEditItem/CreateEditItem';
import { Modal } from 'react-bootstrap';
import { useAppContext } from '../../../libs/contextLib';

const CreateEditItemDialog = (props) => {
    const {
        updateOrCreate,
        isShowing,
        types,
        colors,
        periods,
        tags,
        conditions,
        itemToEdit,
        setCreateDialogVisible,
        setNewlyCreatedItemId,
        setItemToEdit
    } = props;

    const { createItem, updateItem } = useAppContext();

    const handleUpdate = async (item) => {
        const response = updateItem(itemToEdit.id, item);
        setCreateDialogVisible(false);
    };

    const handleCreate = async (item) => {
        const itemId = await createItem(item);
        setCreateDialogVisible(false);
        setNewlyCreatedItemId(itemId);
    }

    const hide = () => { setCreateDialogVisible(false); setItemToEdit(null); };

    return (
        <Modal
            show={isShowing}
            onHide={hide}
        >
            <Modal.Dialog
                size={"full"}
                title={updateOrCreate + " Item"}
            >
                <Modal.Body>
                    {isShowing && (
                        <CreateEditItem
                            typeOptions={types}
                            colorOptions={colors}
                            periodOptions={periods}
                            tagOptions={tags}
                            conditionOptions={conditions}
                            handleSubmit={updateOrCreate === 'Update' ? handleUpdate : handleCreate}
                            displayVerb={updateOrCreate}
                            item={itemToEdit}
                            onCancel={() => { setCreateDialogVisible(false); setItemToEdit(null); }}
                        />
                    )}
                </Modal.Body>
            </Modal.Dialog>
        </Modal>
    );
};

export default CreateEditItemDialog;

