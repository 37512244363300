import React, { useState } from "react";
import { Button, FormGroup, FormControl, FormLabel, NavItem } from "react-bootstrap";
import "./Login.scss";
import { Amplify, Auth } from "aws-amplify";
import { useAppContext } from "../../../libs/contextLib";
import { passwordResetRequired } from '../../../utilities';

const Login = () => {
  const { userHasAuthenticated, setUser, setForgotPasswordClicked } = useAppContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  const handleSubmit = async(event) => {
    event.preventDefault();
    setIsLoggingIn(true);
    try {
      const user = await Auth.signIn(email, password);
      setUser(user);
      if (!passwordResetRequired(user)) {
        userHasAuthenticated(true);
        setIsLoggingIn(false);
      }
    } catch (e) {
      alert(e.message);
      setIsLoggingIn(false);
    }
  }

  Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_POOL_WEB_CLIENT_ID,
    }
  });

  return (
    <div className="Login">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" bsSize="large">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="large">
          <FormLabel>Password</FormLabel>
          <FormControl
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
          />
        </FormGroup>
        <Button block bsSize="large" disabled={!validateForm() || isLoggingIn} type="submit">
          {isLoggingIn ? 'Logging in...' : 'Login'}
        </Button>
      </form>
      <NavItem 
          className='top-nav-bar'
          onClick={() => { setForgotPasswordClicked(true)}}
      >
        Forgot Password
      </NavItem>
    </div>
  );
}

export default Login