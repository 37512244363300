import { useEffect, useState, useCallback, useMemo } from "react";
import axios from 'axios';
import { debounce, isEqual, cloneDeep } from 'lodash';

export const useShowOrderApi = (acaHeaders) => {
    const [showOrders, setShowOrders] = useState([]);
    const [rangeBegin, setRangeBegin] = useState(null);
    const [rangeEnd, setRangeEnd] = useState(null);
    const [isLoadingShowOrders, setIsLoadingShowOrders] = useState(true);
    const [actorChanges, setActorChanges] = useState(null);
    const [lastSubmittedShowOrders, setLastSubmittedShowOrders] = useState(cloneDeep(showOrders));

    const fetchShowOrders = useCallback(async () => {
        setIsLoadingShowOrders(true);
        if(Object.keys(acaHeaders).length <= 0 || acaHeaders.Authorization === 'Bearer null') {
            setIsLoadingShowOrders(false);
            return [];
        }
        const response = await axios.get(process.env.REACT_APP_API_URL + '/show_orders', {
            params: {
                rangeBegin: rangeBegin,
                rangeEnd: rangeEnd
            },
            headers: acaHeaders
        });
        const sortedResult = [...response.data].sort((a, b) => a.submittedAt - b.submittedAt);
        setShowOrders(sortedResult);
        setIsLoadingShowOrders(false);
    }, [rangeBegin, rangeEnd, acaHeaders]);

    useEffect(() => {
        fetchShowOrders();
    }, [fetchShowOrders, acaHeaders]);

    const createShowOrder = async (newShowOrder) => {
        const response = await axios
            .post(process.env.REACT_APP_API_URL + '/show_orders/create', newShowOrder,
                {
                    headers: acaHeaders
                })
            .catch(error => {
                console.log(error);
                return false;
            });
        await fetchShowOrders();
        return response.data.showOrderId;
    };

    const loadShowOrder = useCallback(async (showOrderId) => {
        setIsLoadingShowOrders(true);
        const response = await axios.get(process.env.REACT_APP_API_URL + '/show_orders/' + showOrderId,
            { headers: acaHeaders });
        const updatedShowOrders = showOrders.map((order, index) => {
            if (order.id === showOrderId) {
                return response.data;
            }
            return order;
        });
        setShowOrders(updatedShowOrders);
        setLastSubmittedShowOrders(cloneDeep(updatedShowOrders));
        setIsLoadingShowOrders(false);
        return response.data;
    }, [showOrders]);

    const attributeHasChanged = (showOrderId, attribute, newValue) => {
        const showOrderById = showOrders.find((showOrder) => showOrder.id == showOrderId);
        return (showOrderById[attribute] !== newValue);
    }

    const saveShowOrderAttribute = async (showOrderId, attribute, newValue) => {
        if(attributeHasChanged(showOrderId, attribute, newValue)) {
            const patchRequest = {
                op: "replace",
                path: "/" + attribute,
                value: newValue
            };
            const response = await patch(showOrderId, patchRequest);
            const updatedShowOrders = showOrders.map((order, index) => {
                if (order.id === showOrderId) {
                    return response.data;
                }
                return order;
            });
            setShowOrders(updatedShowOrders);
            setLastSubmittedShowOrders(cloneDeep(updatedShowOrders));
            return true;
        }
    }

    const debouncedSaveShowOrderAttribute = useMemo(
        () => debounce((showOrderId, attribute, newValue) => {
            saveShowOrderAttribute(showOrderId, attribute, newValue)
        }, 1000), [showOrders]
    );

    const saveActor = (showOrderId, actor, actorKey) => {
        let showOrder = lastSubmittedShowOrders.find((showOrder) => showOrder.id == showOrderId);
        if (!(isEqual(showOrder.keyedActors[actorKey], actor))) {
            setActorChanges({ showOrderId, actor, actorKey });
        }
    };

    useEffect(() => {
        if (actorChanges) {
            const { showOrderId, actor, actorKey } = actorChanges;
            replaceActor(showOrderId, actor, actorKey);
        }
    }, [actorChanges]);

    const debouncedSaveActor = useMemo(
        () => debounce((showOrderId, actor, actorKey) => {
            saveActor(showOrderId, actor, actorKey)
        }, 1000), [showOrders]
    );

    const removeShowOrder = async(showOrderId) => {
        const response = await axios.delete(process.env.REACT_APP_API_URL + `/show_orders/${showOrderId}`,
            {
                headers: acaHeaders
            });
        await fetchShowOrders();
    };
    
    const patch = async (showOrderId, patchRequest) => {
        const response = await axios.patch(process.env.REACT_APP_API_URL + '/show_orders/' + showOrderId, 
        patchRequest,
        { headers: acaHeaders } );
        return response;
    };

    const removeActor = async (showOrderId, actorKey) => {
        const patchRequest = {
            op: "remove",
            path: "/keyedActors/" + actorKey
        };
        const response = await patch(showOrderId, patchRequest);
        const updatedShowOrders = showOrders.map((order, index) => {
            if (order.id === showOrderId) {
                return response.data;
            }
            return order;
        });
        setShowOrders(updatedShowOrders);
        setLastSubmittedShowOrders(cloneDeep(updatedShowOrders));
        return true;
    };

    const replaceActor = async (showOrderId, actor, actorKey) => {
        const patchRequest = {
            op: "replace",
            path: "/keyedActors/" + actorKey,
            value: actor
        };
        const response = await patch(showOrderId, patchRequest);
        const updatedShowOrders = showOrders.map((order, index) => {
            if (order.id === showOrderId) {
                return response.data;
            }
            return order;
        });
        setShowOrders(updatedShowOrders);
        setLastSubmittedShowOrders(cloneDeep(updatedShowOrders));
        setActorChanges(null);
        return true;
    };

    return [showOrders, isLoadingShowOrders, { setShowOrders, setRangeBegin, setRangeEnd, loadShowOrder, saveActor: debouncedSaveActor, saveShowOrderAttribute: debouncedSaveShowOrderAttribute, removeActor, createShowOrder, removeShowOrder }];
}

