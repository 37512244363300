import React, { useState, useEffect } from 'react';
import { Button, Carousel } from "react-bootstrap";
import './ImageCarouselWithToolbar.scss';
import ImageDisplay from "../ImageDisplay/ImageDisplay";
import { cameraIcon, deleteIcon } from '../../../libs/icons';

const ImageCarouselWithToolbar = (props) => {
    const {
        items,
        activeCarouselImage,
        onDeleteClick,
        uploadButton,
        onEditClick,
        onCameraClick,
        onCreateClick,
        onSelectClick
    } = props;
    const [activeImage, setActiveImage] = useState(activeCarouselImage);

    useEffect(() => {
        setActiveImage(activeCarouselImage);
    }, [activeCarouselImage]);

    useEffect(() => {
        if (activeCarouselImage > 0) {
            setActiveImage(activeCarouselImage);
        } else {
            setActiveImage(0);
        }
    }, [items, activeCarouselImage]);

    const renderDeleteButton = (index) => {
        let deleteButton = '';
        if (onDeleteClick) {
            deleteButton = (
                <Button
                    className="delete-button"
                    data-testid="delete-button"
                    onClick={() => { onDeleteClick(index) }}
                >
                    {deleteIcon}
                </Button>
            );
        }
        return deleteButton;
    }

    const renderCameraButton = () => {
        let cameraButton = '';
        if (onCameraClick) {
            cameraButton = (
                <Button
                    className="camera-button"
                    onClick={() => onCameraClick()}
                >
                    {cameraIcon('1em')}
                </Button>
            );
        }
        return cameraButton;
    }

    const renderUploadButton = () => {
        return uploadButton ?? '';
    }

    const renderNoImagesDiv = () => {
        return (
            <div className='no-images-div'>
                {renderCreateButton()}
                {renderUploadButton()}
                {renderCameraButton()}
            </div>
        )
    }

    const renderEditButton = (itemId) => {
        let editButton = '';
        if (onEditClick) {
            editButton = (
                <Button
                    className="upload-button"
                    onClick={() => { onEditClick(itemId); }}
                >
                    Edit
                </Button>
            );
        }
        return editButton;
    }

    const renderCreateButton = () => {
        let createButton = '';
        if (onCreateClick) {
            createButton = (
                <Button
                    onClick={onCreateClick}
                    type="text"
                >
                    New
                </Button>
            );
        }
        return createButton;
    }

    const renderSelectButton = (item) => {
        let selectButton = '';
        if (onSelectClick) {
            selectButton = (
                <Button
                    className='select-button'
                    onClick={() => onSelectClick(item)}
                    type="text"
                >
                    Select Item
                </Button>
            );
        }
        return selectButton;
    }

    const menuButtons = (item, index) => {
        return <div className='menu-buttons'>
            {renderSelectButton(item)}
            {renderCreateButton()}
            {renderEditButton(index)}
            {renderCameraButton()}
            {renderUploadButton()}
            {renderDeleteButton(item.id)}
        </div>;
    }

    const renderCarousel = () => {
        return (
            <div>
                <div>#{activeImage + 1} of {items.length}</div>
                <Carousel
                    activeIndex={activeImage}
                    interval={null}
                    slide={false}
                    indicators={false}
                    controls={items.length > 1 ? true : false}
                    onSelect={(selectedIndex) => setActiveImage(selectedIndex)}
                >
                    {
                        items.map((item, index) => {
                            return (
                                <Carousel.Item key={index}>
                                    <ImageDisplay
                                        filename={item.image}
                                        description={item.description}
                                    />
                                    {menuButtons(item, index)}
                                </Carousel.Item>
                            )
                        })
                    }
                </Carousel>
            </div>);
    }

    return (
        items.length ? renderCarousel() : renderNoImagesDiv()
    );
}

export default ImageCarouselWithToolbar;
