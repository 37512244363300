import { useState, useMemo } from "react";
import axios from 'axios';

export const useItemApi = (acaHeaders) => {
    const [items, setItems] = useState([]);
    const [typeFilters, setTypeFilters] = useState([]);
    const [colorFilters, setColorFilters] = useState([]);
    const [tagFilters, setTagFilters] = useState([]);
    const [periodFilters, setPeriodFilters] = useState([]);
    const [isLoadingItems, setIsLoadingItems] = useState(true);

    useMemo(() => {
        const fetchItems = async () => {
            setIsLoadingItems(true);
            const response = await axios.get(process.env.REACT_APP_API_URL + '/items', {
                params: {
                    types: typeFilters,
                    colors: colorFilters,
                    periods: periodFilters,
                    tags: tagFilters,
                }
            });
            setItems([...response.data.Items]);
            setIsLoadingItems(false);
        }

        fetchItems();
    }, [typeFilters, colorFilters, periodFilters, tagFilters]);

    const createItem = async (item) => {

        const response = await axios.post(process.env.REACT_APP_API_URL + '/items/create', item,
            {
                headers: acaHeaders
            })
            .catch(error => {
                console.log(error);
            });
        item['id'] = response.data.itemId;
        setItems([...items, item]);
        return response.data.itemId;
    };

    const updateItem = async (itemId, item) => {
        const response = await axios
            .put(process.env.REACT_APP_API_URL + `/items/${itemId}`, item,
                {
                    headers: acaHeaders
                })
            .catch(error => {
                return error;
            });
        const updatedItems = items.map((oldItem, index) => {
            if (oldItem.id === itemId) {
                item['id'] = itemId;
                return item;
            }
            return oldItem;
        });
        setItems(updatedItems);
        return response;
    }

    const deleteItem = async(itemId) => {
        await axios.delete(process.env.REACT_APP_API_URL + `/items/${itemId}`,
        {
            headers: acaHeaders
        });
        const isNotId = item => item.id !== itemId;
        const updatedItems = items.filter(isNotId);
        setItems(updatedItems);
    }


    const patch = async (itemId, patchRequest) => {
        const response = await axios.patch(process.env.REACT_APP_API_URL + '/items/' + itemId,
            patchRequest,
            { headers: acaHeaders });
        return response;
    };

    const patchItem = async (itemId, dataField, value) => {
        setIsLoadingItems(true);
        const patchRequest = {
            op: "add",
            path: "/" + dataField,
            value: value
        };
        const response = await patch(itemId, patchRequest);
        const updatedItems = items.map((item, index) => {
            if (item.id === itemId) {
                return response.data;
            }
            return item;
        });
        setItems(updatedItems);
        setIsLoadingItems(false);
        return true;
    }

    return [
        items,
        isLoadingItems,
        typeFilters,
        colorFilters,
        periodFilters,
        tagFilters,
        { setItems, setTypeFilters, setColorFilters, setPeriodFilters, setTagFilters, updateItem, createItem, patchItem, deleteItem }];
}

